/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//import { FaBeer } from "react-icons/fa";
import {
  FaCheckCircle,
  FaLightbulb,
  FaPaypal,
  FaPlay,
  FaCog,
  FaRegLightbulb,
  FaShare,
  FaUser,
  FaSignOutAlt,
  FaSignInAlt,
  FaList,
  FaUniversity,
 FaEuroSign
} from "react-icons/fa";

// Chakra imports
import {
   Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
    Grid,
      Switch,
  Link,
  Stack,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";


import { useCallback, useEffect, useState, useRef,useMemo } from 'react';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import CardHeader from "components/Card/CardHeader.js";

import IconBox from "components/Icons/IconBox";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";
//import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {AgGridReact} from 'ag-grid-react';
//import { AgGridColumn, AgGridReact } from "ag-grid-enterprise";

import 'ag-grid-enterprise'; // the AG Grid React Component

 import 'ag-grid-enterprise/styles/ag-grid.css';
 import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
//import 'ag-grid-community/styles/ag-grid.css';
//import 'ag-grid-community/styles/ag-theme-alpine.css';
 // const textColor = useColorModeValue("gray.700", "white");

import cashFlowApi from "utils/cashFlowApi.js";
import "utils/style.css";

import CategoryModal from "views/Components/CategoryModal.js";
import EntryModal from "views/Components/EntryModal.js";
import CategoryEditModal from "views/Components/CategoryEditModal.js";
import CategoryNewModal  from "views/Components/CategoryNewModal.js";

//import ScenarioModal  from "views/Components/ScenarioModal.js";
import VatModal from "views/Components/VatModal.js";
import DragnDropVatModal from "views/Components/DragnDropVatModal";


import SimpleCellRenderer from "./simpleCellRenderer.jsx";
import NumericCellRenderer from "./NumericCellRenderer.jsx";


import { Redirect, useHistory } from "react-router-dom";
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';

import NumericEditor from 'views/Components/numericEditor.jsx';

//import ModalW from './modal.js';
// function onCellValueChanged(params){
//   setRowChange(params)
//  if(debug)console.log(params);
// }
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';
// import { MdBugReport } from "react-icons/md";
// import { FaRegClosedCaptioning } from "react-icons/fa";
// import { types } from "@babel/core";

// import {
//   barChartDataDefault,
//   barChartOptionsDefault,
//   lineChartDataDefault,
//   lineChartOptionsDefault,
// } from "variables/charts";

import LineChart from "components/Charts/LineChart";

import BarChart  from 'components/Charts/BarChart';
import Chart from "react-apexcharts";


import CustomLoadingOverlay from 'views/Components/customLoadingOverlay';

  let modalIsOpen = false;
  let forecastEntries = [];

   let scenarios = [];
  let entryByExpenseCategories=[];
let entryByRevenueCategories = [];




function Forecast(props) {
   let debug=true;
    let path = props.location.pathname.split("/");
  if(debug)console.log("path ::" + path);
    
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
// const { isOpen: isEntryOpen , onOpen: onEntryOpen, onClose: onEntryClose } = useDisclosure();
// const { isOpen: isCategoryOpen , onOpen: onCategoryOpen, onClose: onCategoryClose } = useDisclosure();

  const entryModal = useDisclosure();
  const vatModal = useDisclosure();
  const dragnDropVatModal = useDisclosure();
  const categoryModal = useDisclosure();
  const categoryEditModal =useDisclosure();
  const categoryNewModal =useDisclosure();

 //const ScenarioModal =useDisclosure();

  var defaultParams = {
    'data' : {
        'poste' : '',
        'category_id ':''
    }
};

  const [params, setParams] = useState(defaultParams);

function currencyFormatter(params) {
  return '£' + formatNumber(params.value);
}
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
   const [gridParams, setGridParams] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);


  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [columns2, setColumns2] = useState([]);
  const [rowData2, setRowData2] = useState([]);
  const [startRow, setStartRow] = useState([]);
  const [endRow, setEndRow] = useState([]);
         
  //const [chartMonth, setChartMonth] = useState([]);
  const [chartMonth, setChartMonth] = useState([]);

let history = useHistory();

let [categories,setCategories]=useState([]);
let [selectedCategory, setSelectedCategory] = useState(null);
let [revenueToCategorize,setRevenueToCategorize]=useState([]);
let [expenseToCategorize,setExpenseToCategorize]=useState([]);

let [vatCollected,setVatCollected]=useState([]);
let [vatPaid,setVatPaid]=useState([]);


let [monthsColums, setMonthsColums] = useState([]);

//let [startDate, setStartDate] = useState(null);
let [startDateFormated, setStartDateFormated] = useState(null);
//let [finishDate, setFinishDate] = useState(null);
let [finishDateFormated, setFinishDateFormated] = useState(null);

let [recurringInterval, setRecurringInterval] = useState(null);
let [recurring, setRecurring] = useState(true);
let [fixedAmount, setFixedAmount] = useState();
let [vatSelected, setVatSelected] = useState();

let [disabled, setDisabled] = useState();

let [bankBalance, setBankBalance] = useState();
let [bankBalanceDate, setBankBalanceDate] = useState();

const monthsToShow=12;

  const [rowDragCategory, setRowDragCategory] = useState([]);
  const [vatScheme, setVatScheme] = useState();
  const [vatExtra, setVatExtra] = useState();


const isCellEditable = (params) => {
    let isEditable=false;
   if(debug)console.log(params);
    //if(params.node.rowPinned  === undefined && params.data.poste!='TVA à reverser')isEditable=true;

            if (params.data != undefined) {
                   if (
                     params.data.poste != undefined 
                     && params.colDef.field !== "poste"
                     && params.data.poste !== "TVA à reverser"
                     && params.data.poste !== "Solde début"
                     &&  params.data.poste !== "Solde fin") return true;
                  }else  isEditable =true;
  return isEditable;
};


  const [movingDataSaved, setMovingDataSaved] = useState([]);

var rowDrag = function (params) {
  let result=false;
  // only rows that are NOT groups should be draggable
  if(params.node.group === false && params.data.poste !=="TVA à reverser")result=true;
  return result;
};

  const onRowDragMove = useCallback((event) => {
    var movingNode = event.node;
    var overNode = event.overNode;
    // find out what country group we are hovering over
    var groupCategory;
   if(debug)console.log(event);
    if (overNode.group) {
      // if over a group, we take the group key (which will be the
      // country as we are grouping by country)
    // if(debug)console.log(overNode.parent.key );
      groupCategory = overNode.key;
    } else {
      // if over a non-group, we take the country directly
      groupCategory = overNode.data.category;
    }
    var needToChangeParent = movingNode.data.category !== groupCategory;
    if (needToChangeParent && overNode.parent.key==movingNode.data.type) {
      var movingData = movingNode.data;
     if(debug)console.log(movingData);
      movingData.category = groupCategory;
     if(debug)console.log(groupCategory);
      gridRef.current.api.applyTransaction({
        update: [movingData],
      });
      gridRef.current.api.clearFocusedCell();
    }
  }, []);

function  onRowDragEnter(dragEvent){
    //var userAgent = window.navigator.userAgent;
   if(debug)console.log(dragEvent);
    if(dragEvent.node.data.category!==undefined)setRowDragCategory(dragEvent.node.data.category);
    // dragEvent.dataTransfer.setData(
    //   'text/plain',
    //   'Dragged item with ID: ' + this.props.node.data.id
    // );
  }


const onVatSend=(vatSelected)=>{

 let report =  cashFlowApi.putReport(reportUuid,vatSelected,null);
     //report.then( (report) => updateVatData(report,gridParams));
            //let report = cashFlowApi.getReport(reportUuid); 
          //let report = reports["hydra:member"][0];
         // updateData(report,params);
          //.then(testPinned  );
          
    
  vatModal.onClose();
}

function totalYearAmountCalculation(months,previousMonths,startDateMonth,actualMonth){
   let startMonth=vatExtra.getActualYear.startDate;
   let finishMonth= vatExtra.getActualYear.finishDate;
   let totalYearAmount=0;  
   if(debug)console.log(months);

      for (const [month, amount] of Object.entries(previousMonths)) {
         if(month>= startDateMonth && month <actualMonth){
            totalYearAmount+=Number(amount.amount);
            if(debug)console.log(month+' '+ Number(amount.amount) +' '+ totalYearAmount + ' ' +startDateMonth + ' '+actualMonth);
         }
      }

   for (const [month, amount] of Object.entries(months)) {
      if(isNaN(month))continue
        if(month <=finishMonth && month>=startMonth){
            totalYearAmount+=Number(amount);
            if(debug)console.log(month+' '+ Number(amount) +' '+ totalYearAmount + ' ' +finishMonth + ' '+startMonth);
            }
   } 
         // Object.entries(months.forEach(([month, amount]) => {
         //    if(month <=finishMonth|| month>=startMonth){
         //       totalYearAmount+=amount;
         //       }
         // }));
       
       return totalYearAmount;
}

function debugThis(value,label){
   if(debug)console.log( label+' '+value);
}

function debugThisObject(value,label){
   if(debug)console.log( label+' '+ JSON.stringify(value));
}

function onVatSelected(vatId,entryId,vatRate){
   
     //let movingNode=movingDataSaved;
   let diffVatRate=vatRate-movingDataSaved.data.vat;

   let startDate=forecastEntries[movingDataSaved.data.id].startDate;
   let startMonth=startDate.substring(0,4)+startDate.substring(5,7);

   let finishDate=forecastEntries[movingDataSaved.data.id].finishDate;
   let finishMonth=null;
   if(debug)console.log(finishDate);
   if(finishDate!==null && finishDate!==undefined) finishMonth=finishDate.substring(0,4)+finishDate.substring(5,7);

   if(debug)console.log(vatId,entryId);
   if(debug)console.log(movingDataSaved);
   if(debug)console.log(forecastEntries[movingDataSaved.data.id]);
   if(debug)console.log(forecastEntries[movingDataSaved.data.id].startDate);
   if(debug)console.log(startMonth);
   if(debug)console.log(finishMonth);
 
   let amount=movingDataSaved.data[monthsColums[0]];
   let diffVatAmount=0;
   let previousVatRate=movingDataSaved.data.vat;
   if(movingDataSaved.data.category=='A catégoriser')previousVatRate=0;
   if(previousVatRate!=vatRate){
       cashFlowApi.putEntryVat(entryId,vatId);
       diffVatAmount=getDiffVatAmount(amount,previousVatRate,vatRate);
   }
   

   let vatAmount=Math.round(getVatFromAmountTtc(amount,vatRate));
  if(vatScheme.value=='/api/vat_schemes/2') {
 let totalYearAmount= totalYearAmountCalculation(movingDataSaved.data,forecastEntries[movingDataSaved.data.id].months,startDate,monthsColums[0]);

    if(debug)console.log(totalYearAmount);

  }
  // if(startMonth<monthsColums[0])
   if(vatScheme.value=='/api/vat_schemes/1')   updateVatColMonth(monthsColums[0],vatAmount);
   let amountTotal=0;
   // const propertyNames = Object.keys( monthsColums);
   Object.entries(movingDataSaved.data).forEach(([key, value]) => {
      let month=key;
      let amount=movingDataSaved.data[month];
      let previousVatRate=movingDataSaved.data.vat;

      if(finishMonth>month || startMonth> month){

       }

      else {
     if(debug)console.log("is isNaN : "+ key + ' =>' +isNaN(key) +' ' +vatScheme.value)
      if(!isNaN(key)) {
         if(movingDataSaved.data.type_id=='1')amount=-amount;

         if(vatScheme.value=='/api/vat_schemes/1') {
            let vatResult= getVatMonth(month,diffVatAmount);
            let vatCol=vatResult[1];
            updateVatCell(amount,previousVatRate,vatRate,vatCol,startMonth,finishMonth);
         }     
         else if(vatScheme.value=='/api/vat_schemes/2')  {
            let next=vatExtra.previousYear.nextDepositMonths;
             let declarationDate=vatExtra.getActualYear.dateToDeclare;
               amountTotal+=amount;

              if(debug)console.log(month + ' '+ amountTotal +' ' + amount);
            if(declarationDate==month){
              if(debug)console.log(month + ' '+ amountTotal +' ' + vatRate)
                  updateVatCell(amountTotal,0,vatRate,month,startMonth,finishMonth);
               //amountTotal=0;
            }
            else if(next[month]!==undefined){
                 if(debug)console.log(month + ' '+ next[month] + ''+ amountTotal +' ' + vatRate);
                updateVatCell(Math.round(totalYearAmount*next[month]),0,vatRate,month,startMonth,finishMonth);
            }
            //  for (let i = 0; i < next.length; i++) {  
            //   str = next[i]["month"];

            //   if (month==next[i]["month"] || ){
            //   if(debug)console.log(next[i]["month"]) + ''+next[i]["rate"]));
            //     }   
            // }
   
            // if (month==vatExtra.previousYear.nextDepositMonth){

            //   //  updateVatCell(amount,previousVatRate,vatRate,month,startMonth,finishMonth);
            // }

         }
        if(debug)console.log("vat : "+ month + ":" + value+ ":" + previousVatRate+ ":" + vatRate);


         }

      }
    });
   // for (const [key, month] of Object.entries(monthsColums)) {

   // }

      //)


   //agGridPeriodsUpdateData(movingNode,movingNode,movingNode.data,false,vatRate,true,movingNode.data.type_id,false);
   dragnDropVatModal.onClose();
}

function getVatFromAmountTtc(amountTtc,vatRate){
   let vatAmount=amountTtc-(amountTtc/(1+vatRate/100));

   return  vatAmount;
}

function getDiffVatAmount(amount,previousVatRate,newVatRate){
   if(previousVatRate==newVatRate)return '0';

   let previousVatAmount=getVatFromAmountTtc(amount,previousVatRate);
   let newVatAmount=getVatFromAmountTtc(amount,newVatRate);
   let diffVatAmount=Math.round(newVatAmount-previousVatAmount);

   if(debug)console.log("previous : "+previousVatAmount+" ,new : "+newVatAmount+" ,diff : "+diffVatAmount);
   return diffVatAmount;
}

function updateVatCell(amount,previousVatRate,newVatRate,month,startMonth,finishMonth){
   var topPinnedRow= gridRef.current.api.getPinnedTopRow(0);
   var bottomPinnedRow= gridRef.current.api.getPinnedBottomRow(0);

   let diffVatAmount=getDiffVatAmount(amount,previousVatRate,newVatRate);
   let newBottomPinnedValue=0;
   let newTopPinnedValue=0;
   let vatCol=month;
//   let vatAmount=vatResult[0];
   updateVatColMonth(vatCol,diffVatAmount);

  for (const [key, month] of Object.entries(monthsColums)) {
  if(debug)console.log(month +' '+ startMonth + ' '+vatCol);
      if(month<vatCol && month < startMonth)continue;
      if(monthsColums[0]<month) topPinnedRow.setDataValue(month, newBottomPinnedValue);
      

      let vatAmountMonthStart=0;
      let vatAmountMonthFinish=0;
      //if(vatCol<month && month >= startMonth)
      vatAmountMonthStart=diffVatAmount;
      if(vatCol<=month && (finishMonth > month|| finishMonth===null)) vatAmountMonthFinish=diffVatAmount;
      if(isNaN(amount)) amount = 0;
      amount=Number(amount);

      newTopPinnedValue= topPinnedRow.data[month] -vatAmountMonthStart;
      newBottomPinnedValue= bottomPinnedRow.data[month] -vatAmountMonthFinish;
     if(debug)console.log(topPinnedRow.data[month], bottomPinnedRow.data[month]);
     if(debug)console.log(key, month,vatCol,amount, newTopPinnedValue, newBottomPinnedValue , vatAmountMonthStart,vatAmountMonthFinish);
     console.log(monthsColums);

     ///if(col<=month) 
      bottomPinnedRow.setDataValue(month, newBottomPinnedValue);

    }
}

function onRowDragEnd(event) {
    var movingNode = event.node;
    var overNode = event.overNode;
   if(debug)console.log(event);
     moveRow(movingNode,overNode);
 }



function moveRow(movingNode,overNode) {
    // find out what country group we are hovering over
    var groupCategory;
    var groupCategoryId;
    var groupTypeName;
    var groupTypeId;
    let entries=[];

      //entries=entriesByCategories[groupCategoryId];
  

    // on over a category
    if (overNode.group) {
      if(overNode.groupData.poste=="A catégoriser") {
         if(debug)console.log("a categoriser");
        }
        else {
    // if over a group, we take the group key (which will be the
      // country as we are grouping by country)
    // if(debug)console.log(overNode.parent.key );
      groupCategory = overNode.key;
      groupCategoryId = overNode.allLeafChildren[0].data.category_id;

     {
         if(debug)console.log(groupCategory);
         if(debug)console.log(movingNode.data);
         if(debug)console.log(entriesByCategories);
         if(debug)console.log(groupCategoryId);
         if(debug)console.log(entriesByCategories[groupCategoryId]);
         //if(debug)console.log(overNode.allLeafChildren);
        // if(debug)console.log(entriesByCategories[groupCategoryId].length);
         }

      Object.entries(overNode.allLeafChildren).forEach(([key, value]) => {
      //   if(debug)console.log(key +' : "/api/forecast_entries/"'+value.data["id"]);
      //if(debug)console.log(entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]));
      if(entriesByCategories[groupCategoryId]){
      if(!entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]) ){
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+value.data["id"]);
           if(debug)console.log("/api/forecast_entries/"+value.data["id"]);
           }

      }

               //  entries.push("/api/forecast_entries/"+value.data["id"]);
         });
        
      entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+movingNode.data["id"]);
      entries=entriesByCategories[groupCategoryId];

      groupTypeName=overNode.parent.key;
      groupTypeId="api/forecast_types/"+overNode.allLeafChildren[0].data.type_id;   
      }
  
    } else if(overNode.parent.parent!==undefined ) {
      // if over a non-group, we take the country directly
      groupCategory = overNode.data.category;
      groupCategoryId = overNode.data.category_id;
      groupTypeName=overNode.parent.parent.key;
      groupTypeId="api/forecast_types/"+overNode.data.type_id;

            Object.entries(overNode.parent.allLeafChildren).forEach(([key, value]) => {
      //   if(debug)console.log(key +' : "/api/forecast_entries/"'+value.data["id"]);
      //if(debug)console.log(entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]));
      if(entriesByCategories[groupCategoryId]){
      if(!entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]) ){
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+value.data["id"]);
           if(debug)console.log("/api/forecast_entries/"+value.data["id"]);
           }
      }
      else entriesByCategories[groupCategoryId]=[];


    });
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+movingNode.data["id"]);
           entries=entriesByCategories[groupCategoryId];
          } 
    var needToChangeParent = movingNode.data.category !== groupCategory;
   if(debug)console.log(needToChangeParent);
    if(debug)console.log(groupCategory);
     //if(debug)console.log(groupType);
    if(debug)console.log(movingNode.data.type_id);
    if(debug)console.log(movingNode.data.type);
    if(debug)console.log(groupTypeName);
     let overNodeTypeId=null;
     if(groupTypeName=='Encaissements')overNodeTypeId='2';
      else if(groupTypeName=='Décaissements')overNodeTypeId='1';
     if(debug)console.log(overNodeTypeId);
     if(debug)console.log(overNodeTypeId==movingNode.data.type_id);
    if (needToChangeParent && overNodeTypeId==movingNode.data.type_id ) {
      var movingData = movingNode.data;
       if(debug)console.log(entriesByCategories);
          entries.push('/api/forecast_entries/'+movingData.id);
     if(debug)console.log(movingData);

      setMovingDataSaved(movingNode);
      openDragnDropVatModal(movingData.id);
      let newEntry=false;
      let updateSummaries=false;
      if(movingNode.data.category=='A catégoriser'){
         newEntry=true;
         updateSummaries=true;
      }
      movingData.category = groupCategory;
      movingData.type = groupTypeName;
      gridRef.current.api.applyTransaction({
        update: [movingData],
      });
      gridRef.current.api.clearFocusedCell();

     //putCategory(groupCategoryId,groupCategory,groupTypeId,entries);
     putCategory(groupCategoryId,movingNode.data.id);
     let entry=forecastEntries[movingNode.data.id];
     agGridPeriodsUpdateData(movingNode,movingNode.data,movingNode.data.disabled,movingNode.data.vat,updateSummaries,movingNode.data.type_id,newEntry,entry);
    }
    setRowDragCategory();
  };


let [id, setId] = useState();

let [revenueCategories,setRevenueCategories]=useState([]);
let [expenseCategories,setExpenseCategories]=useState([]);
let [entriesByCategories, setEntriesByCategories]=useState([]);
let getCategories =false;
let entryToCategorize='A catégoriser';

const [bankAccount, setBankAccount] = useState();

useEffect(() => {
  if(!getCategories){
    //getForecastCategories()
  } 

      }, [getCategories]);

function getForecastCategories(company){

  if(!getCategories){
      //let getCategories = true;
          ///if(debug)console.log("length"+categories.length);
        let revenuesCategories;
        let expensesCategories=[];
        let entriesByCategoriesTemp=[];
        if (expenseCategories.length==0) {
        //cashFlowApi.getAccount('15121')
        getCategories = cashFlowApi.getCategories(company);       
        //let products = paymentApi.getProducts();   
      
        getCategories.then( (getCategories) =>{
        let entriesValues=[];

        if(getCategories===undefined){
                 if(debug)console.log(getCategories);

          return <Redirect to="/authentication/sign-in" />;

        }
        if(debug)console.log(getCategories["hydra:member"]);
          Object.entries(getCategories["hydra:member"]).forEach(([key, value]) => {

            //entriesByCategoriesTemp[value["@id"]]=value.entry;
            if(value["name"]==entryToCategorize){}
            else if(value["type"]=="/api/forecast_types/1")expenseCategories.push({value:value["@id"],label:value.name});
            else  revenueCategories.push({value:value["@id"],label:value.name});
          entriesValues=[];
        Object.entries(value.entry).forEach(([key, value]) => {
         if(debug)console.log(value["@id"]);
               entriesValues.push(value["@id"]);
            //setCategory(value["@id"]);

         });

          entriesByCategoriesTemp[value["@id"]]=entriesValues;

            //setCategory(value["@id"]);

         });

        setEntriesByCategories(entriesByCategoriesTemp);
       if(debug)console.log(entriesByCategoriesTemp);
  
        setExpenseCategories(expenseCategories);
        setRevenueCategories(revenueCategories);
       if(debug)console.log("length2"+categories.length);

        });
      } 
  }
}

 // const [showEnptyCategories, setShowEnptyCategories] = useState(true);
var showEnptyCategories = true;   

// function isExternalFilterPresent(): boolean {
//       //console.log('show empty :'+showEnptyCategories);
//   // if showEnptyCategories is not everyone, then we are filtering
//   return !showEnptyCategories ;
// }

// function doesExternalFilterPass(node: IRowNode<rowData>): boolean {
//    console.log('show empty :'+showEnptyCategories);
//    console.log('show node : '+node);

//   if (node.data) {
//     switch (showEnptyCategories) {
//       case false:
//         return node.data.poste != null;
//       default:
//         return true;
//     }
//   }
//   return true;
// }

// function externalFilterChanged(value) {
//    console.log(value);
//    if(showEnptyCategories==true)setShowEnptyCategories(false);
//    else setShowEnptyCategories(true);
//    console.log(showEnptyCategories);
//   gridApi.onFilterChanged();
// }


  const externalFilterChanged = useCallback((newValue: string) => {
   // showEnptyCategories = newValue;
   showEnptyCategories=newValue.checked
     //    if(showEnptyCategories==true)setShowEnptyCategories(false);
     // else setShowEnptyCategories(true);
     console.log(newValue.checked);
    gridRef.current.api.onFilterChanged();

  }, []);

  const isExternalFilterPresent = useCallback((): boolean => {
    // if showEnptyCategories is not everyone, then we are filtering
    return !showEnptyCategories ;//!== true;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<rowData>): boolean => {
      if (node.data) {
        switch (showEnptyCategories) {
          case false:
            return node.data.poste != null;
  
          default:
            return true;
        }
      }
      return true;
    },
    [showEnptyCategories],
  );


  const columnTypes = useMemo(() => {
    return {
      editableColumn: {
        editable: (params) => {
          return isCellEditable(params);
      }//,
      //   cellStyle: (params) => {
      //     if (isCellEditable(params)) {
      //       return { backgroundColor: 'lightBlue' };
      //     }
      //   },
       },
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      // 'sick-days-warning': (params) => {
      //   var numSickDays = params.data.sickDays;
      //   return numSickDays > 5 && numSickDays <= 7;
      // },
      // row style expression
      //'red': 'data.type === "Décaissements"',
      //||data === "undefined" ||node.parent.key === "Décaissements" || node.key === "Décaissements" 
      'red': 'data.type_id === "1"',
     // ||node.parent.key === "Encaissements" || node.key === "Encaissements"
      'green': 'data.type_id === "2" ',
      'blue': 'data.rowPinned === "top" ',
      'blue': 'data.rowPinned === "bottom" ',
      // 'green': 'data.poste === "Solde fin"',
    };
  }, []);


const getRowClass = params => {
  if(params.data  !== undefined){
        if (params.data.type_id === "1" ){
        return 'red';

    }
    else if(params.data.type_id === "2" ){
        return 'green';

    }
  }
  else if(params.node.parent.key === "Décaissements" || params.node.key === "Décaissements" || params.node.parent.key === "Décaissements à catégoriser" || params.node.key === "Décaissements à catégoriser" ) return 'red';
  else if(params.node.parent.key === "Encaissements" || params.node.key === "Encaissements" || params.node.parent.key === "Encaissements à catégoriser" || params.node.key === "Encaissements à catégoriser" ) return 'green';
  else if(params.data.rowPinned === "top" || params.data.rowPinned === "bottom") return 'blue';

};

  const gridOptions = {
      domLayout: 'autoHeight',

    //  rowClassRules: rowClassRules ,
      getRowClass: getRowClass,
      getRowId: params => params.data.id,
          onCellClicked: (params) =>  {
       if(debug)console.log(params);

//     let vatRow = gridRef.current.api.getPinnedTopRow(1);
// vatRow.setDataValue('202308',100 );
// vatRow.setDataValue('202309',110 );
// vatRow.setDataValue('202310',120 );
// vatRow.setDataValue('202311',130 );
// vatRow.setDataValue('202312',140 );
//    if(debug)console.log(vatRow);

        if (params.data != undefined) {
                   if (
                     params.data.poste != undefined &&
                     params.colDef.field == "poste"
                     &&  params.data.poste !== "TVA à reverser"
                     &&  params.data.poste !== "Solde début"
                     &&  params.data.poste !== "Solde fin"
                   ) {
                         openModal(params);
                       return false;
                   }
         else if(params.data.poste === "TVA à reverser")return false;//openVatModal(params);
        }
   
        else if(params.node.group && params.node.key !="Décaissements" && params.node.key !="Encaissements" ) {
                      //categoryEditModal.onOpen();
                       return false;

        }
      },
      onCellDoubleClicked: (params) =>  {
       if(debug)console.log(params);

        if (params.data != undefined) {
                   if (
                     params.data.poste != undefined 
                     && params.colDef.field !== "poste"
                     //&& params.data.poste !== "TVA à reverser"
                     && params.data.poste !== "Solde début"
                     &&  params.data.poste !== "Solde fin"
                   ) {
                   if(debug)console.log("onCellDoubleClicked");
                        // openModal(params);
                       return false;
                   }
                   else{
                    if(debug)console.log("tyup");
                    // return true;
                   } 
     
        } else if (params.data == undefined) {
          if (params.node.level == 2) {
           if(debug)console.log("2");
          } else if (params.node.level == 0) {
           if(debug)console.log("0");
          } else if (params.node.level == 1) {
           if(debug)console.log("1");
          }
          else if (params.data.poste !== "TVA à reverser") {
           if(debug)console.log("TVA à reverser");
          }
         if(debug)console.log("open");
          return false;
        }
      },
        groupDisplayType: 'custom',
  suppressRowClickSelection: true,
  groupDefaultExpanded: 0,
  rowSelection: 'multiple',
  groupSelectsChildren: true,
  animateRows: true,
  suppressAggFuncInHeader :true,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
    ,
  isExternalFilterPresent: isExternalFilterPresent,
  doesExternalFilterPass: doesExternalFilterPass
  };

// gridOptions.getRowClass = function(params) {
//   if (params.node.group) {
//     return 'background-green';
//   }
// }
    const gridOptions2 = {
        domLayout: 'autoHeight',
      rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
     // onCellDoubleClicked: (params) =>  //if(debug)console.log(params)
  }
    let aggCallCount = 0;


const myAggFunc = (params) => {
  aggCallCount++;
  let total = 0;
  let amount = 0;
     
  //if(debug)console.log(params);
  for (let i = 0; i < params.values.length; i++) {
  // if(debug)console.log(i+' : ' + params.values[i]);
    amount=params.values[i];

   //if(debug)console.log(amount);

    if(isNaN(amount)) amount = 0;
    if(params.rowNode.allLeafChildren[i]!==undefined){

         if(params.rowNode.allLeafChildren[i].data.disabled===false || params.rowNode.allLeafChildren[i].data.disabled === undefined || params.rowNode.level=='0'   ){
    //if(debug)console.log('ok');  
    //if(debug)console.log(params); 
      total += parseFloat(amount);
  }
   else  {
   //if(debug)console.log("nok");  
    //if(debug)console.log(params);
   }
    }



  }

  return total;
};


function openModal(params){
   if(debug)console.log(params,'params open modal');
if(debug)debugThisObject(forecastEntries,'debug forecastEntries');
    setParams(params);
    if(debug)debugThisObject(forecastEntries[params.data.id],'forecastEntries');
    setSelectedCategory({
      value:
      //  "/api/forecast_categories/" +
        params.data.category_id,
      label: params.data.category,
    });

    setCategoryId(params.data.category_id);
    setCategoryName(params.data.category);
    if(debug)console.log(forecastEntries,'forecast open modal');
    setId(params.data.id);
    setCategoryType(params.data.type_id);

    if (forecastEntries[params.data.id]){
      setRecurring(forecastEntries[params.data.id].recurring);
      setFixedAmount(forecastEntries[params.data.id].amount);
      setRecurringInterval(forecastEntries[params.data.id].recurringInterval);
      setVatSelected(forecastEntries[params.data.id].vat);
      setStartDateFormated(forecastEntries[params.data.id].startDate);
      //if(debug)console.log(forecastEntries[params.data.id].finishDate);
      setFinishDateFormated(forecastEntries[params.data.id].finishDate);
      setDisabled(forecastEntries[params.data.id].disabled);
    }

    modalIsOpen = true;
   entryModal.onOpen();
}

function openDragnDropVatModal(entry){
   setId(entry);
  if(debug)console.log(entry);
    dragnDropVatModal.onOpen();
}

function openVatModal(params){
    //modalIsOpen = true;
    vatModal.onOpen();
}

function categoryEdit(){
   setCategoryType('2');
   categoryEditModal.onOpen();
}

function addNewCategory(id,name,categoryType){
   if(categoryType=="2"){
      revenueCategories.push({value:id,label:name});
      setRevenueCategories(revenueCategories);
   }
   else{
      expenseCategories.push({value:id,label:name});
      setExpenseCategories(expenseCategories);
   }
}

function categorizeExpense(){
   setCategoryType('1');
   setCheckedItems([]);
   setCategoryName('');
   // test2('api/entries/sdfsfsf');
   categoryModal.onOpen();
}

function categorizeRevenue(){
      setCategoryType('2');
      setCheckedItems([]);
      setCategoryName('');
      categoryModal.onOpen();
}

function newExpense(){
  openNewModal(1);
}

function newRevenue() {
  openNewModal(2);
}

function openNewModal(categoryType,category_id,categoryName) {
  let params=[];
  params["data"]="";
  setParams(params);
    setSelectedCategory({
      value:category_id,
      label:categoryName
    });
   //if(debug)console.log(selectedCategory);
    setCategoryName(categoryName);
    setCategoryId(category_id);
  //setVatSelected(defaultVat);
  setId();
  setCategoryType(categoryType);



  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

let todayFomated = yyyy+'-' + mm + '-' + dd ;
//let todayFomated = dd+'/' + mm + '/' + yyyy ;
        if(debug)console.log(today);
       setRecurring(true);
       setFixedAmount();
     //  let vat={}
  setVatSelected({"value":'/api/vats/1',"label":"20.00"});
   // setVatSelected({"value":'2',"label":"10.00"});
      setRecurringInterval( {label : 'Mensuel', value: '/api/intervals/1'});
      // setStartDate(today);
      setStartDateFormated(todayFomated);
       setFinishDateFormated();
       //setFinishDate();
         setDisabled(false);
        if(debug)console.log("disadisadisadisadisa : "+disabled);
  modalIsOpen = true;
  entryModal.onOpen();

  // resetMonths();
  // setParams(params);
  // onOpen();
}


function openModalTest(params){
 
    showRow();
    resetMonths();
    setRow(params.data.num_line);
   if(debug)console.log(params);
    setName(params.data.num);
    setMappedName(params.data.num);
    setAmount(params.data.mean);
    setMonths();
    setMonthPeriods();
    monthToShow=[];
    monthstoPeriod=[];

    let actual_month="";
    Object.entries(params.data.amount_by_month).forEach(([key, value]) => {
          actual_month=key;
            Object.entries(value).forEach(([key, value]) => {

            monthstoPeriod.push(actual_month+':'+value.period);
  
            eval("set"+actual_month+'Amount')(value.amount);

        });

      });
  if(params.data.mean<0){
  setTitle('Dépense');
  setCategories(expenseCategories);
  setCategory("/api/forecast_categories/2");
  setSelectedCategory({ value: "/api/forecast_categories/2", label: 'Loyers' });
}
else {
  setTitle('Revenu');
  setCategories(revenueCategories);
    setCategory("/api/forecast_categories/4");
    setSelectedCategory({ value: "/api/forecast_categories/4", label: 'Ventes' });
}
     setMonths(monthToShow);
     setMonthPeriods(monthstoPeriod);

if(params.data.ecart==0)setShowVariableAmount(0);

  let p={ value: "/api/intervals/"+params.data.interval, label: params.data.periodicity_label };
  //if(debug)console.log(p);
  setPeriodicitySelected(p);
  setPeriodicity("/api/intervals/"+params.data.interval);
  setStart(params.data.next_date);
 // onOpen();
}


function pinnedRow() {
  var result = [];
//gridApi.forEachNode(printNode);
if(debug)console.log("pinned");
//         gridOptions.api.forEachNode((rowNode, index) => {
//    if(debug)console.log(index + 'node ' + rowNode + ' is in the grid');
// });
    //if(debug)console.log( gridOptions.api.getDisplayedRowAtIndex(1) );
  // for (var i = 0; i < count; i++) {
  //   result.push({
  //   id: 'tot',
  //   name: 'tot',
  //   city:'tot',
  //   distro: 'tot',
  //   laptop: 'tot',
  //   value: 'tot',
  //   });
  // }
//if(debug)console.log(rowdata);
  // rowData= params.columnApi.getRow(0);
  //  result=rowData[0];

    //  gridOptions.api.setPinnedBottomRowData(rows);
  return result;
}

  const printNode = (node, index) => {
    if (node.group) {
     if(debug)console.log(index + ' -> group: ' + node.key);
    } else {
     if(debug)console.log(
        index + ' -> data: ' + node.data.post 
      );
    }
  };

    const onBtExport = useCallback(() => {
      //openDragnDropVatModal();
    gridRef.current.api.exportDataAsExcel();
  }, []);

  function launchStarter(){
       props.history.push("/report/start");
  }

  function goCategories(type){
   console.log(type);
     //props.history.push("/categories");
  }

//<Redirect push to="/report/start" />;

  function test(params){

 //let a = params.api.getRenderedNodes();

// let bottom=[];
// let top=[];
// bottom.push(a[0].data);
// top.push(a[1].data);

// params.api.setPinnedTopRowData(top);
// params.api.setPinnedBottomRowData(bottom);
if(debug)console.log(startRow);
// params.api.setPinnedBottomRowData(startRow);
// params.api.setPinnedTopRowData(endRow);

  }
   
   const [reportId, setReportId] = useState([]);
   const [reportUuid, setReportUuid] = useState([]);

  const onGridReady = (params) => {
     //if(debug)console.log("tiop ");
      //  var rowNode = params.api.getRowNode(0);
      setGridParams(params);
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);

   

        // let reports = cashFlowApi.getReports();   
      
        // reports.then((reports)=>{
        //   setReportId(reports["hydra:member"][0]["@id"]);
        //   setCompany(reports["hydra:member"][0]["company"]);
        //   setBankAccount(reports["hydra:member"][0]["accounts"][0]["id"]);
        //   let uuid=reports["hydra:member"][0]["@id"].split("/");
     
       let companies= cashFlowApi.getCompanies(); 
          companies.then((companies)=>{
            if(companies===undefined){
                       //if(debug)console.log(getCategories);
                props.history.push("/authentication/sign-in");
                return false;
                //return <Redirect to="/authentication/sign-in" />;

              }
        
         let company=companies["hydra:member"][0]["@id"];
         let reportId=companies["hydra:member"][0]["report"][0]["@id"];
         let companyJp = localStorage.getItem("companyJp");
          let  uuid=companies["hydra:member"][0]["report"][0]["id"];
          console.log(company);
         if(companyJp!==null){
             Object.entries(companies["hydra:member"]).forEach(([key, value]) => {
              // console.log(value["jePilote"][0]["jpId"]);
              if(value["jePilote"]){
                  if(value["jePilote"]["jpId"]==companyJp) {
                  company=value["@id"];
                  reportId=value["report"][0]["@id"];
                  uuid=value["report"][0]["id"];
                  console.log(value["name"]+" "+reportId+" "+company);
                  }
              }
            })
          }
          
           getForecastCategories(company);

          

   
        if(debug)console.log(companies["hydra:member"][0]["report"]);
        if(debug)console.log(companies["hydra:member"][0]["report"][0]);

         // let uuid=companies["hydra:member"][0]["report"][0].split("/");

          //if(debug)console.log("reports" +uuid[3]);
                  //let accountId=companies["hydra:member"][0]["report"][0]["account"][0]).split("/")[3];
          // let accounts = cashFlowApi.getAccount(accountId); 
         
          // accounts.then((companies)=>{
          
          //let userId=companies["hydra:member"][0]["user"][0]["id"];

         //let user= cashFlowApi.getUser(userId); 
         // user.then(()=>{
    

            setReportUuid(uuid);
          let report = cashFlowApi.getReport(uuid); 
          //let report = reports["hydra:member"][0];
         // updateData(report,params);
          //.then(testPinned  );
         setReportId(reportId);
         setCompany(company);

          report.then( (report) => updateData(report,params,company,reportId)   
                      )
          .then(testPinned  );


                  //});
        });
//});
            const testPinned= ()=> {
            // if(debug)console.log("pinned Test!!!")

test(params);
//if(debug)console.log("pinned");
      }

       //params.api.forEachNode(printNode);
   //if(debug)console.log( rowNode() );
//       params.api.forEachNode((rowNode, index) => {
//    if(debug)console.log(index + 'node ' + rowNode + ' is in the grid');
// });
  //if(rowData[0] !== undefined) if(debug)console.log(rowData[0]);
   //var makeCol = params;
  //if(debug)console.log("tiop "+makeCol);
// makeCol.colDef.headerName="New";
// params.api.refreshHeader();
  // gridOptions.api.setPinnedBottomRowData(rows);
   // setGridApi(params.api);

  };

  let monthsColumsArray=[];

  const updateData= (report,params,company,reportId) =>{
   if(debug)console.log(reportId);
    if(debug)console.log(company);

      //setBankAccount(report["accounts"][0]["id"]);
      setVatScheme({value :report["vatScheme"]["@id"],label : report["vatScheme"]["name"]});
      setVatExtra(report["test"]["vatExtra"]);
      let col=[];
      let colNum=0;
      let entryToCategorizeExpense=[];
      let entryToCategorizeRevenue=[];
      col.push({ headerName: 'type', field: 'type', rowGroup: true ,   hide: true}); 
      col.push({ headerName: 'category', field: 'category', rowGroup: true ,   hide: true}); 
      col.push({ headerName: 'id', field: 'id',  hide: true}); 
      col.push({ headerName: 'periods', field: 'periods',  hide: true}); 
      col.push({ headerName: 'disabled', field: 'disabled',  hide: true}); 
// col.push(    {
//       headerName: 'Checkbox Cell Editor',
//       field: 'disabled',
//       cellEditor: 'agCheckboxCellEditor',
//     });
      col.push({
      headerName: "Postes",
      field: "poste",
      showRowGroup: true,
      animateRows:true,
      suppressMovable :true,
      cellRenderer: "agGroupCellRenderer",
      //rowDrag: rowDrag,  
      minWidth: 340,
      cellRendererParams: {
      suppressCount: true, // turn off the row count (in order to skip default stack counting)
     // innerRenderer: customInnerRenderer, //our inner renderer in charge of child nodes counting
    //editable: true,
      innerRenderer:SimpleCellRenderer
    },

  });

  //col.push({  headerName: 'Group Renderer C',      field: 'poste',      minWidth: 240,      showRowGroup: true,      cellRenderer: 'agGroupCellRenderer',      cellRendererParams: {        suppressCount: true,              innerRenderer: SimpleCellRenderer,        suppressDoubleClickExpand: true,        suppressEnterExpand: true      }});
 

      //col.push({headerName: 'Poste', field: 'poste', sortable: true ,  width: 180}); 
      //
      let headername='';
      let monthToChart=[];
      Object.entries(report.period).forEach(([key, value]) => {
            if(colNum<monthsToShow){
              monthsColumsArray.push(key);
              monthToChart.push(value);
              col.push({headerName:value, field: key.replace('-', ''),
             // sortable: true ,
         
              width: 85,  
              cellClass: 'ag-right-aligned-cell' , 
            //  cellDataType: 'number',
              enableCellChangeFlash: true,
              suppressMovable :true,
             // valueParser: params => Number(params.newValue),
             valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 0})',
               // cellEditor: 'agNumberCellEditor',
                    cellEditor: NumericEditor,
                // cellEditorParams: {
                //   precision: 0,
                // },
                 type: 'numericColumn',
             aggFunc: myAggFunc,
              //type: 'editableColumn',
              cellRenderer: NumericCellRenderer ,
              editable:  (params) => {
          return isCellEditable(params);
      },


    //               cellRendererParams: {

    //   innerRenderer:NumericCellRenderer
    // },
                  
              }); 

            colNum++;
            }

      });
      // const updateChartData =()=>
      // {
      let newTotalExpense=[];
       Object.entries(report.test.expenses.total).forEach(([key, value]) => {
         if(value!='Total dépenses')        newTotalExpense.push(value);
       });
       //console.log("tuit"+newTotalExpense);

      let newTotalRevenue=[];
       Object.entries(report.test.revenues.total).forEach(([key, value]) => {
         if(value!='Total rentrées')        newTotalRevenue.push(value);
       });
      // console.log("tuit"+newTotalRevenue);



      let balancesStart=[];
       Object.entries(report.test.balanceWithVat.start).forEach(([key, value]) => {
         if(key!='poste')        balancesStart.push(value);
       });
      // console.log("tuit"+balancesStart);

      let balancesFinish=[];
       Object.entries(report.test.balanceWithVat.finish).forEach(([key, value]) => {
         if(key!='poste')         balancesFinish.push(value);
       });
     console.log("tuit"+balancesFinish);

   // const newData = chartData.datasets[0].data.map(() => Math.floor(Math.random() * 100));
    setLineChartDataDefault(
         [
           {
             name: "Encaissements",
             data: newTotalRevenue,
        
           },
           {
             name: "Décaissements",
             data: newTotalExpense,
         
           }
         ]        
          );

    setBalancesSeries(
         [
           {
             name: "Solde Fin de mois",
             data: balancesFinish
           }
         ]        
          );

           setChartMonth(monthToChart);
           console.log("monthtot"+monthToChart);


      col.push({headerName:'data', field: 'data',hide:true});
      setBankBalance(0);

      if(report.accounts[0]){
      let balanceDate = new Date( report.accounts[0].checkingDate);

      setBankBalanceDate(() => ( format(balanceDate, 'dd/MM/yyyy') : ''));

      setBankBalance(Number(report.accounts[0].balance).toLocaleString('fr-FR'));
        
      }


          setMonthsColums(monthsColumsArray);
          setColumns(col);
          setColumns2(col);
         let row=[];

         let row_col=[];
         let row_col2=[];
         let entries = report.forecastEntry;
         //if(Object.entries(entries).length==0)openVatModal();
          Object.entries(entries).forEach(([key, value]) => {
            let id=value["@id"].split("/")[3];
            let vat = null;
            if(value.vat !==null && value.vat!==undefined){
              vat={label : value.vat.rate, value: value.vat["@id"]};
             if(debug)console.log(vat);
            }
           let type_id ="1";
           let type='Décaissements';
           if(value.type=="/api/forecast_types/2"){
               type_id ="2";
               type='Encaissements';
           }

            forecastEntries[id] = {
              startDate: value.startDate,
              finishDate: value.finishDate,
              recurring: value.recurring,
              amount:value.amount,
              vat : vat,
              disabled:value.disabled,
              recurringInterval: {label : value.recurringInterval.label, value: value.recurringInterval["@id"]},
              months:value.months[0],
              oldMonths:value.months[0],
              type: type,
              type_id: type_id,
              category:value.category.name,
              category_id:value.category.id,
              months:value.months,
              name:value.name
            };

            if(value.scenarios.length>0){
                  Object.entries(value.scenarios).forEach(([key, scenario]) => {
                        // scenarios[scenario.id]= [value.id];
                    });

            }

            console.log(scenarios);
          //if(debug)console.log(
          //   key +
          //     " " +
          //     value["@id"].split("/")[3] +
          //     " " +
          //     value.startDate +
          //     " " +
          //     value.finishDate +
          //     " " +
          //     value.recurring +
          //     " " +
          //     value.recurringInterval
          // ); 
                    });

                   //if(debug)console.log(forecastEntries);

                   //if(debug)console.log(forecastEntries[470]);
          let datas=report.test;
          //let datas=report.forecast;

   let start=[];
   start.push(datas["balanceWithVat"]["start"]);
   start.push( datas["balanceWithVat"]["vat"]);

   //let vatPaid=[];
    setVatPaid(datas["vatPaid"]);
   // params.api.setPinnedBottomRowData(end);

   //let vatCollected=[];
    setVatCollected(datas["vatCollected"]);
    //params.api.setPinnedBottomRowData(end);

   params.api.setPinnedTopRowData(start);

    let end=[];
    end.push(datas["balanceWithVat"]["finish"]);
    params.api.setPinnedBottomRowData(end);


   //  let vat=[];
   // vat.push(Object.entries(datas[7]["balanceWithVat"]["vat"])
    let vatRow=[];        


   // params.api.setPinnedTopRowData(vatRow);

//row_col.push( datas[7]["balanceWithVat"]["vat"]);
//
 //     if(debug)console.log(datas[7]["balanceWithVat"]["start"]);
 // row_col.push( datas[7]["balanceWithVat"]["start"]);
 // row_col.push( datas[7]["balanceWithVat"]["vat"]);
 //  row_col.push( datas[7]["balanceWithVat"]["finish"]);

  
    //params.api.setPinnedBottomRowData('vat');
// for (const [key, value] of Object.entries(datas[0])) {
  
//   for (const [key, value] of Object.entries(value)) {
//     // b=value;
//     //  if(debug)console.log("efsdfsfsfsfs");
//     //if(debug)console.log(b);
//     //if(debug)console.log(`${key}: ${value}`)
//   }
// ;
// }
// let a=null;
// for (const [key, value] of Object.entries(datas[3])) {
//   a=value;
//  //if(debug)console.log(`${key}: ${value}`);
// }

//if(debug)console.log(a);
//if(debug)console.log(b);

         // Object.entries(datas[0]).forEach(([key, value]) => {
            //row_col.push(value);
            
           // let b=value;
                       // setStartRow(value);

            // Object.entries(value).forEach(([key, value]) => {
            // });
  
       // });
       // var as = JSON.parse(datas[1]);

      var countExpense = Object.keys(datas["expenses"]).length;
      var countRevenue = Object.keys(datas["revenues"]).length;
       if(countExpense<2 &&  countRevenue <2){
           launchStarter();
     
       }

// if(debug)console.log("toto " +countExpense);
//  if(debug)console.log("toto " +countRevenue);
//if(debug)console.log(datas[2]);





        // let revenueToCategorize=[];
        //   Object.entries(datas[2]).forEach(([categoryName, value]) => {
        //   let revenueEntries = [];
        //   Object.entries(value).forEach(([name, value]) => {
        //          //if(debug)console.log(categoryName+" ::: "+value.poste);
        // if(name!="total"){

        //            if(typeof value=='object') {
        //                                 value.poste=name;
        //             value.type='Encaissements';
        //             value.type_id='2';
        //            //if(debug)console.log('b '+value["id"]);
        //               if (categoryName == entryToCategorize) {
        //                                         value.type='Encaissements à catégoriser';
        //                 //if(debug)console.log(categoryName+" ::: "+name);
        //                 entryToCategorizeRevenue.push([
        //                   name,
        //                   "api/forecast_entries/" + value["id"],
        //                 ]);
        //                 revenueToCategorize.push(value);
        //               } else {
        //                 //    entryByCategories[categoryName][value["id"]]="api/forecast_entries/" + value["id"];
        //                 revenueEntries.push(
        //                   "api/forecast_entries/" + value["id"]
        //                 );
        //                 row_col.push( value);
        //               }


        //                   //row_col2.push( value);
        //            }
                  
        //        }
        // });
        //     entryByRevenueCategories[categoryName] = revenueEntries;
        // setRevenueToCategorize(entryToCategorizeRevenue);
        //        });
        //        
        if(debug)console.log(datas["revenues"]);
      revenuesRows(datas["revenues"],row_col,categories);

      expensesRows(datas["expenses"],row_col);
        
        //if(debug)console.log(entryByExpenseCategories);
        //if(debug)console.log(entryByRevenueCategories);
        // end total
         // Object.entries(datas[3]).forEach(([key, value]) => {

           // let a=value;
           // setEndRow(value);

           // row_col.push( value);
           
           
            // row_col2.push( value);
            //bottomRow.push(value);
           // gridOptions.api.setPinnedBottomRowData(bottomRow);
 
       // });

        //   Object.entries(datas[4]).forEach(([key, value]) => {
        //     row_col.push( value);
        // });
          
        //   Object.entries(datas[5]).forEach(([key, value]) => {
        //          row_col.push( value);
        //   });
             
        //   Object.entries(datas[6]).forEach(([key, value]) => {
        //          row_col.push( value);
        //   });
             

          // Object.entries(datas[7]["balanceWithVat"]["start"]).forEach(([key, value]) => {
          //        row_col.push( datas[7]["balanceWithVat"]["start"]);
          // });
          //if(debug)console.log(row_col);
          setRowData(row_col);

          //  setRowData2(row_col2);
    
  }


  const updateVatData= (report,params,company,reportId) =>{
    if(debug)console.log(reportId);
    if(debug)console.log(company);

      //setBankAccount(report["accounts"][0]["id"]);
      setVatScheme({value :report["vatScheme"]["@id"],label : report["vatScheme"]["name"]});
      setVatExtra(report["test"]["vatExtra"]);

      // let entryToCategorizeExpense=[];
      // let entryToCategorizeRevenue=[];

      // if(report.accounts[0]){
      //     let balanceDate = new Date( report.accounts[0].checkingDate);
      //     setBankBalanceDate(() => ( format(balanceDate, 'dd/MM/yyyy') : ''));
      //     setBankBalance(Number(report.accounts[0].balance).toLocaleString('fr-FR'));
      // }
      
   // let row=[];


    let datas=report.test;
 
   let start=[];
   start.push(datas["balanceWithVat"]["start"]);
   start.push(datas["balanceWithVat"]["vat"]);

    setVatPaid(datas["vatPaid"]);

    setVatCollected(datas["vatCollected"]);

    params.api.setPinnedTopRowData(start);

    let end=[];
    end.push(datas["balanceWithVat"]["finish"]);
    params.api.setPinnedBottomRowData(end);

   //  let vat=[];
   // vat.push(Object.entries(datas[7]["balanceWithVat"]["vat"])
    let vatRow=[];        

      var countExpense = Object.keys(datas["expenses"]).length;
      var countRevenue = Object.keys(datas["revenues"]).length;
       if(countExpense<2 &&  countRevenue <2){
           launchStarter();
       }

    //if(debug)console.log(datas["revenues"]);
    // let row_col=[];
    // revenuesRows(datas["revenues"],row_col,categories);
    // expensesRows(datas["expenses"],row_col);    
    // setRowData(row_col);    
  }

function expensesRows(datas,row_col){
   let expenseToCategorize=[];
   let usedCategories=[];

          let entryToCategorizeExpense=[];
          Object.entries(datas).forEach(([categoryName, value]) => {
            let expenseEntries = [];
            Object.entries(value).forEach(([name, value]) => {
            
               if(name!="total"){
     
                   if(typeof value=='object') {
                      value.poste=name;
                      value.type='Décaissements';

                      value.type_id='1';

                      if(categoryName==entryToCategorize){
                        value.type='Décaissements à catégoriser';
                       // value.category='';
                      //if(debug)console.log(categoryName+" ::: "+name);
                      entryToCategorizeExpense.push([name,"api/forecast_entries/"+value["id"] ]);
                     if(debug)console.log(value.poste);
                      expenseToCategorize.push(value);
                      }
                      else{
                    //    entryByCategories[categoryName][value["id"]]="api/forecast_entries/" + value["id"];
                          expenseEntries.push("api/forecast_entries/" + value["id"]);
                          usedCategories[categoryName]='1';
                         if(debug)console.log(value);
                          row_col.push(value); 
                      }
        
 
                   }
                  
               }//

            });

            console.log(usedCategories);

          entryByExpenseCategories[categoryName] =expenseEntries;
        setExpenseToCategorize(entryToCategorizeExpense);
        });
       
      let row= [];
       let i=0;
      Object.entries(expenseCategories).forEach(([categoryName, value]) => {
                 // console.log(categoryName);
         if(debug)console.log(value);
         if(debug)console.log(usedCategories[value.label]);
         if(usedCategories[value.label]===undefined) {
            if(debug)console.log(value.label);
            row_col.push( {category:value.label,category_id:value.value,type:'Décaissements',type_id:'1',poste:null,id:++i});
         }
       });

expenseToCategorize.forEach(function callback(value, index) {
 if(debug)console.log(`${index}: ${value}`);
   row_col.push(value); 
});

}

function revenuesRows(datas,row_col){

        let revenueToCategorize=[];
         let usedCategories=[];

          let entryToCategorizeRevenue=[];
          Object.entries(datas).forEach(([categoryName, value]) => {
          let revenueEntries = [];
          Object.entries(value).forEach(([name, value]) => {
               //if(debug)console.log(categoryName+" ::: "+value.poste);
              if(name!="total"){
                if(typeof value=='object') {
                value.poste=name;
                 value.type='Encaissements';
                 value.type_id='2';
                //if(debug)console.log('b '+value["id"]);
                   if (categoryName == entryToCategorize) {
                        value.type='Encaissements à catégoriser';
                     //if(debug)console.log(categoryName+" ::: "+name);
                     entryToCategorizeRevenue.push([
                       name,
                       "api/forecast_entries/" + value["id"],
                     ]);
                     revenueToCategorize.push(value);
                   } else {
                     //    entryByCategories[categoryName][value["id"]]="api/forecast_entries/" + value["id"];
                     if(value.scenario ===undefined)revenueEntries.push(
                       "api/forecast_entries/" + value["id"]
                     );
                        else {
                           console.log(forecastEntries[value["id"]]);
                        }
                     if(debug)console.log(value.scenario);
                     usedCategories[categoryName]='1';
                     if(value.scenario ===undefined)row_col.push(value);
                     else { 
              
                        Object.entries(value.scenario).forEach(([id]) => {
                           console.log(value.id);
                            scenarios[id]=[value.id];
                        scenarios[id][value.id]=value; 
                     });
                     }
                   }
                       //row_col2.push( value);
                }
                        
               }
        });
         entryByRevenueCategories[categoryName] = revenueEntries;
        setRevenueToCategorize(entryToCategorizeRevenue);
               });
      let row= [];
      let i=0;
      console.log(scenarios);
      Object.entries(revenueCategories).forEach(([categoryName, value]) => {
                 // console.log(categoryName);
         if(debug)console.log(value);
         if(debug)console.log(usedCategories[value.label]);
         if(usedCategories[value.label]===undefined) {
            if(debug)console.log(value.label);
            row_col.push( {category:value.label,category_id:value.value,type:'Encaissements',type_id:'2',poste:null,id:++i});
         }
       });
         // if(!usedCategories["auto"])console.log('auto pas trouvé');
         // if(usedCategories["auto"])console.log('auto trouvé');
         // if(usedCategories["Ventes CB"])console.log('Ventes CB  trouvé');
      revenueToCategorize.forEach(function callback(value, index) {
         if(debug)console.log(`${index}: ${value}`);
         row_col.push(value); 
      });
}



function SortByName(x, y) {
 if(debug)console.log(x.label + ":" + y.label);
  return x.label == y.label ? 0 : x.label > y.label ? 1 : -1;
}

function onPostCategory(categoryName){
      let result= cashFlowApi.postCategory(categoryName,'/api/forecast_types/'+categoryType,company,'');
        result.then( (categorie) =>{

          addCategory2(categorie["@id"],categoryName,categoryType);

          categoryNewModal.onClose();
        });
}

  function addCategory(data){
    let previousCategories=[];
    if (categoryType=='1'){
      previousCategories = expenseCategories;
      previousCategories.push(data);
   //   previousCategories.sort(SortByName);
      previousCategories.sort((a, b) => a.label.localeCompare(b.label));
      setExpenseCategories(previousCategories);
    } 
    else {
      previousCategories= revenueCategories;
      previousCategories.push(data);
     //  previousCategories.sort(SortByName);
       previousCategories.sort((a, b) => a.label.localeCompare(b.label));
      setRevenueCategories(previousCategories);
    }

  }


function addCategory2(uuid,name,type){

  let previousCategories=[];

  previousCategories=entriesByCategories
  previousCategories[uuid]=[];
  previousCategories.sort((a, b) => a.label.localeCompare(b.label));
  setEntriesByCategories(previousCategories);

  let  category='Décaissements';
  if(categoryType=="2")category='Encaissements';

  let newCategorie={category:name, category_id:uuid,label:name,type:category,type_id:categoryType,poste:null,id:null};
     // row_col.push({category:value.label,category_id:value.value,type:'Encaissements',type_id:'2',poste:null,id:null});
  if(type=="/api/forecast_types/1"){
    let previousExpenseCategories=[];

    previousExpenseCategories=expenseCategories;
    previousExpenseCategories.push(newCategorie);
    previousExpenseCategories.sort((a, b) => a.label.localeCompare(b.label));
    setExpenseCategories(previousExpenseCategories);
  } 
  else  {
    let previousRevenueCategories=[];

    previousRevenueCategories=revenueCategories;
    previousRevenueCategories.push(newCategorie);
    previousRevenueCategories.sort((a, b) => a.label.localeCompare(b.label));
    setRevenueCategories(previousRevenueCategories);
  }

  let newRow=[];
  newRow.push(newCategorie);

  gridApi.applyTransaction({add:newRow});
  sortByNameAsc();
}

function sortByNameAsc() {
  gridColumnApi.applyColumnState({
    state: [{ colId: 'label', sort: 'asc' }],
    defaultState: { sort: null },
  });
}

const   onCellValueChanged= (params)=>{
      // if(debug)console.log(params);
        //if(debug)console.log('modalIsOpen :' + modalIsOpen);
        //modalIsOpen= entryModal.isOpen;
        if (!modalIsOpen) {
           //if(debug)console.log('modalIsOpen => ' + modalIsOpen);
          if (
            isNaN(params.value) &&
            params.colDef.field != "poste" &&
            params.colDef.field != "periods"
          ){//if(debug)console.log("params"+ params);
            alert("saisir un nombre");
        }
          if (params.colDef.field == "id" || params.colDef.field == "periods") {
           if(debug)console.log("no change");
          } else {
            if(params.value===null){
           if(debug)console.log("vide");
            var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
              rowNode.setDataValue(params.colDef.field, 0);
           }
            sendEntry(params);
         }
        }

   
       }

  function sendEntry(params){
   if(debug)console.log(params);
    if(params.rowPinned!==undefined)return '';
    if(params.rowPinned=='bottom')return '';
    if(params.rowPinned=='top')return '';
   // if(params.type=="cellValueChanged" && params.oldValue===undefined)return '';
    //    if(params.node.id=="0")return '';
  


    //if(params.type=="cellValueChanged" )return '';
    let showVariableAmount=0;
 
    let entryPeriods=[];

    let amount="0";
    let periodId='';
    let monthPeriod='';
    let periodicity='';
    /* variables amounts into DB */
    let name=params.data.poste;
    let type_id="api/forecast_types/"+params.data.type_id;
    //if(debug)console.log("category :" +params.data.category_id);
   // let categoryId="api/forecast_categories/"+params.data.category_id;
    let categoryId=params.data.category_id;  
    let id=params.data.id;
    let recurring=true;
    let startDate='';
    let finishDate='';
    let vatSelected='';
    let    vatSelectedLabel='';

      if (forecastEntries[params.data.id]){
         recurring=forecastEntries[params.data.id].recurring;
         // setFixedAmount(forecastEntries[params.data.id].amount);
         // setRecurringInterval(forecastEntries[params.data.id].recurringInterval);
          startDate=forecastEntries[params.data.id].startDate;
          finishDate=forecastEntries[params.data.id].finishDate;

          vatSelected=forecastEntries[params.data.id].vat.value;
          vatSelectedLabel=forecastEntries[params.data.id].vat.label;
        }


    let label='';
    let bankId='';
   if(debug)console.log(params);
   if(debug)console.log(id);
    let newEntry= false;
    if(debug)console.log('yeah');
   
    if(id==undefined || id =='')newEntry=true;
    //if(debug)console.log("id :"+id);
    //if(params.data.periods!=undefined) { 
     //if(debug)console.log(params.data.periods[params.colDef.field]);
     let amountPeriod=0;
     let month = null;
     let months=[];

      for (  month in params.data) {

      if (
        month == "disabled" ||
        month == "category" ||
        month == "poste" ||
        month == "type_id" ||
        month == "type" ||
        month == "category_id"
      ) {
        break;
      } else if (month == "id") {
        break;
      } else if (params.rowPinned !== undefined) break;
      //if(debug)console.log(params);
        // example :  month = 200301
        monthPeriod=month.substring(4);
        months.push(month);
        amountPeriod=params.data[month].toString();
        periodId=null;

        if(newEntry==true){
          startDate=month.substring(0,4)+"-"+monthPeriod+"-01";
          amount=amountPeriod.toString();

        }

        //if(debug)console.log(month);
        if(params.data.periods!=undefined) {
          periodId = params.data.periods[month];
         if(debug)console.log(params.data.periods);

        } 
   
       if(debug)console.log(" periodid "+periodId);
       if(debug)console.log("amount "+ amountPeriod);
        if(periodId!=undefined && periodId!=null && amountPeriod !="0" ){

          entryPeriods.push({
              id:periodId,
              amount:amountPeriod.toString(),
              period:monthPeriod
          });
        }
        else if(!isNaN(amountPeriod) && amountPeriod !="0" ){
          entryPeriods.push({
              //id:null,
              amount:amountPeriod.toString(),
              period:monthPeriod,
              recurringInterval:"/api/intervals/9"
          });
        }
        //if(debug)console.log(month +";"+params.data[month]+":"+params.data.periods[month]);
      }
     //if(debug)console.log(entryPeriods);
    //}
      let response='';
      if(newEntry==true && entryPeriods.length>0 && params.data.id !== undefined  ){
          periodicity="/api/intervals/10";
        //if(debug)console.log("reports" +uuid[3]);

        // response = putEntry(
        //   params.data.type_id,
        //   params.data.category_id,
        //   params.data,
        //   params.data.periods,
        //   params.data.poste
        // ); 

        // response =  cashFlowApi.postEntry(reportId,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
         //  report.then( (report) => updateData(report,params))
        response.then( (response) => {
          //if(debug)console.log(response.data);

          let uuid=response.data["@id"].split("/");
          //if(debug)console.log("reports" +uuid[3]);
          // if(debug)console.log("id"+response.data["@id"]);
          //if(debug)console.log(gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex));
             var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
              rowNode.setDataValue('id', uuid[3]);

                //if(debug)console.log(response.data["entryPeriods"]);
                //        if(debug)console.log(response.data["entryPeriods"][0]);
                //  if(debug)console.log(response.data["entryPeriods"][0]["@id"]);
              //let a= response.data["entryPeriods"][0]["@id"].split("/");
                       //if(debug)console.log(a[3]);
                    setForecastEntries(response);

                let vatRate= response.vat["rate"];
                let fromScenario=false;
              agGridAddPeriods(response,rowNode,type_id,params,false,vatRate,newEntry,response["disabled"],fromScenario);


             // updateSummaries(params,params.colDef.field,amount);
 
        } 
        );         

      }
    else if(newEntry==false && params.data.id !== undefined){

  

          //if (entryPeriods.length>1) 
          periodicity = "/api/intervals/10";
        //  else periodicity=
        //if(debug)console.log("newEntry false :  "+ amount);
        //response = cashFlowApi.putEntry(id,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
      //  let entryPeriods = getEntryPeriods(params.data);
     if(debug)console.log(params);
    if(debug)console.log('yeah');
   
            let entryId=params.data.id;
            let month=params.colDef.field;
            let amount=params.newValue.toString();
            let rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
            let typeId=params.data.type_id;
           // let categoryId=params.data.category_id;
            let category=params.data.category;
            let poste=params.data.poste;
              agGridAddPeriodsSingleCell(month,amount,rowNode,typeId,params,newEntry);

              response = putEntry(
                entryId,
                amount,
                typeId,
                categoryId,
                entryPeriods,
                //params.data.periods,
                poste,
                periodicity,
                "",
                params,
                recurring,
                startDate,
                finishDate,
                false,
                categoryId,
                category,
                false,
                vatSelected,
                vatSelectedLabel,
                disabled
              ); 

      
      
      //   response.then( (response) => {
      //     var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
      //     agGridAddPeriods(response,rowNode);
      //  //   updateSummaries(params,params.colDef.field,amount);
      //       });
    }

    // else if(newEntry==false){
    //       periodicity="/api/intervals/10";
    //   response = cashFlowApi.putEntry(id,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
    //   response.then( (response) => {
    //       var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
    //       agGridAddPeriods(response,rowNode);
      
    //         });
    // }


      // if(typeof month !== null && params.data.id !== undefined ){
      //   let oldValue=0;
      //   if(params.oldValue!==undefined)oldValue=params.oldValue
      //   let newAmount=params.newValue-oldValue;
      //   if(params.data.type_id=="1")newAmount=-newAmount;
      //   updateSummaries(monthsColums,params.colDef.field,newAmount);
      // }

      if(showVariableAmount){
     //tricks for api
      newPeriodicity='/api/intervals/10';
      //if(debug)console.log(monthPeriods );
      monthPeriods.forEach((key) => {
        /* key = 200.49:2022-12-01 => amount, last date, after previous month */
       //if(debug)console.log(key);
        key=key.split(':');
        newAmount=eval(key[0]+'Amount');
        if(newAmount<0){
            type="api/forecast_types/1";
            newAmount=-newAmount;
        }
        else {
            type="api/forecast_types/2";
        }
        newStartDate=key[1];
        //let periodicity=
        //if(debug)console.log("value "+key[0] +' '+eval(key[0]+'Amount') +',date :' +key[0],);
        entryPeriods.push({amount:newAmount.toString(),recurringInterval:newPeriodicity,period:key[1]});
      // cashFlowApi.postEntry(report,type,newAmount.toString(),name,category,newPeriodicity,recurring,newStartDate,finish);

        });
   //if(debug)console.log();
  }
  }

function putEntry(
  id,
  amount,
  typeId,
  category,
  entryPeriods,
  // periods,
  name,
  periodicity,
  periodicityLabel,
  params,
  recurring,
  startDate,
  finishDate,
  isOpen,
  categoryIdFromModal,
  categoryNameFromModal,
  fromModal,
  vatSelected,
  vatSelectedLabel,
  disabled
) {

  {
        if(debug)console.log(categoryIdFromModal);
        if(debug)console.log(categoryNameFromModal);
        if(debug)console.log(fromModal);
        if(debug)console.log(vatSelected);
        if(debug)console.log(vatSelectedLabel);
        if(debug)console.log(disabled);
        if(debug)console.log(isOpen);
        if(debug)console.log(params);
   }

  let showVariableAmount = 0;
  //let entryPeriods = [];
  modalIsOpen = isOpen;
  /* variables amounts into DB */
   let fromScenario=false;
  let type_id = "api/forecast_types/" + typeId;
  //if(debug)console.log("category :" +params.data.category_id);
  //let category_id = "api/forecast_categories/" + categoryId;
  //let category_id = category;

  // let startDate = "";
  // let finishDate = "";
  let label = "";
  let bankId = "";

  let months = entryPeriods;
 if(debug)console.log("modalIsOpen :" + modalIsOpen);
  //return "";
    let mappedName = null;
  if (params.data.num !== undefined) mappedName = params.data.num;
 if(debug)console.log("params.data.id " + params.data.id);
 if(debug)console.log(params);
  //}
  let response = "";

  let newEntry = false;
  if (id == undefined || id == "") newEntry = true;

  if ( newEntry == true
    // &&    params.data.id !== undefined
  ) {
    if (entryPeriods.length < 1) entryPeriods = [];
    else periodicity = "/api/intervals/10";
    //if(debug)console.log("reports" +uuid[3]);
    response = cashFlowApi.postEntry(
      reportId,
      type_id,
      amount,
      name,
      category,
      periodicity,
      recurring,
      startDate,
      finishDate,
      entryPeriods,
      mappedName,
      bankId,
      vatSelected
    );
    response.then((response) => {
     if(debug)console.log(response);
      entryModal.onClose();
      let type='Décaissements';
      if (typeId == "2")  type = "Encaissements";
       let uuid = response["@id"].split("/");
          let row = [
            {
               id: uuid[3],
              category: categoryNameFromModal,
              category_id: category,
              type: type,
              type_id: typeId,
              poste: name,
              vat:response.vat["rate"],
            },
          ];
     //if(debug)console.log(row);
      let a = gridRef.current.api.applyTransaction({ add: row });
//if(debug)console.log(a["add"]);
   if(debug)console.log(a["add"][0]);
      var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
     //if(debug)
      if(debug)console.log(rowNode);
      if(debug)console.log(rowNode.parent);
      if(debug)console.log(rowNode.parent.rowIndex);
      // forEach.rowNode.parent.allLeafChildren.for
       var parentRowNode = gridRef.current.api.getDisplayedRowAtIndex(rowNode.parent.rowIndex);
         if(debug)console.log(parentRowNode);
         parentRowNode.allLeafChildren.forEach((row) => {
            console.log(row.data.poste);
            if(row.data.poste===null) {
               var toRemove = gridRef.current.api.getDisplayedRowAtIndex(row.rowIndex);
                console.log();
              //gridRef.current.api.applyTransaction({ remove: [toRemove.data] });
            } 
         });
//       gridRef.current.api.forEachNode((parentRowNode, index) => {
//     console.log('node ' + parentRowNode.data.poste + ' is in the grid');
// });
      //let uuid = response["@id"].split("/");
      //rowNode.setDataValue("id", uuid[3]);
     // rowNode.setDataValue("poste", response["name"]);
      setForecastEntries(response);
      let vatRate= response.vat["rate"];
      setSelectedCategory({
         value: response["category"]["@id"],
         label: response["category"]["name"],
       });

      agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate,newEntry,response["disabled"],fromScenario);

           var parentRowNode = gridRef.current.api.getDisplayedRowAtIndex(rowNode.parent.rowIndex);
         if(debug)console.log(parentRowNode);
         parentRowNode.allLeafChildren.forEach((row) => {
            console.log(row.data.poste);
            if(row.data.poste===null) {
               console.log(row.data);
               var toRemove = gridRef.current.api.getDisplayedRowAtIndex(row.rowIndex);
              console.log(toRemove);
              //     setTimeout(() => {
              // console.log(toRemove);
                 gridRef.current.api.applyTransaction({ remove: [toRemove.data] });
         // }, 2000);
                        
            } 
         });
//         forecastEntries[uuid[3]] = {

//               startDate: startDate,
//               finishDate: finishDate,
//               recurring: recurring,
//               amount:amount,
//               recurringInterval: {label :periodicityLabel, value: periodicity}
//         };

//if(debug)console.log(forecastEntries);


    // setTimeout(() => {
    //   modalIsOpen = false;
    // }, 1000);
       // updateSummaries(params,params.colDef.field,amount);
    });


    // } else if (newEntry == false && params.data.id !== undefined) {
    // periodicity = "/api/intervals/10";
    //if(debug)console.log("newEntry false :  " + amount);

    // response.then((response) => {
    //   var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
    //agGridAddPeriods(response, rowNode);
    //   //   updateSummaries(params,params.colDef.field,amount);
    // });
  } else if (newEntry == false) {
    if (entryPeriods.length < 1){
            entryPeriods = [];
          //  rowNode.setDataValue("periods", null);
    } 
    else periodicity = "/api/intervals/10";

    response = cashFlowApi.putEntry(
      id,
      type_id,
      amount,
      name,
      category,
      periodicity,
      recurring,
      startDate,
      finishDate,
      entryPeriods,
      label,
      bankId,
      vatSelected,
      disabled
    );

   if(debug)console.log(params);
       if(debug)console.log('yeah');
      console.log(forecastEntries[id],'forecastEntries[id]');
    response.then((response) => {
     if(debug)console.log(response);
      let vatRate= response.vat["rate"];
       var rowNode =   gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
    entryModal.onClose();
            //let a = gridRef.current.api.applyTransaction({ add: row });
//if(debug)console.log(a["add"]);,
  //   if(debug)console.log(a["add"][0]);
    //  var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
  if(response.disabled!=true) {
 const itemsToUpdate = [];
  
      const data = rowNode.data;
     data.category= categoryNameFromModal;
     data.category_id=category;
     data.poste=response.name;
       if (data.category!="A catégoriser") {
           if(typeId==1) data.type='Décaissements';
           else  data.type='Encaissements';
       }
          if (entryPeriods.length < 1){
            data.periods=null
          //  rowNode.setDataValue("periods", null);
    } 
          itemsToUpdate.push(data);
          let res ='';
          if(params.type!="cellValueChanged")  res = gridRef.current.api.applyTransaction({ update: itemsToUpdate });
      //if(debug)console.log(forecastEntries[params.data.id][month]);
//       if(oldValue!=forecastEntries[params.data.id][months][0])oldValue=forecastEntries[params.data.id][month];
   
      response.oldMonths=forecastEntries[params.data.id][months];
      setForecastEntries(response);

     if (data.category!="A catégoriser") {
 if(debug)console.log('yeah','2');
  //  printResult(res);
           // let a = gridRef.current.api.applyTransaction({ add: row });
//if(debug)console.log(a["add"]);
  //   if(debug)console.log(a["add"][0]);
    //  var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
   //  if(debug)console.log(res);

          // rowNode.setDataValue("category", categoryNameFromModal);
          // rowNode.setDataValue("poste", response["name"]);  
          // rowNode.setDataValue("category_id", categoryIdFromModal);
       
     // if(params.oldValue!==undefined) 
         agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate,newEntry,forecastEntries[id],response["disabled"],fromScenario);

     }
  }


      if(response.disabled==true){ 
         const res = gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }

     if(debug)console.log(forecastEntries);
         // modalIsOpen = false;
    });

  }
}


function setForecastEntries(response){
  //if(debug)console.log(response);
  let uuid = response["@id"].split("/");
if(debug)debugThisObject(forecastEntries,'debug forecastEntries');
  let vat = null;
  if(response["vat"] !==null){
    vat={label : response.vat.rate, value: response.vat["@id"]};
    //if(debug)console.log(vat);
  }

  let oldMonths=[];
  if(forecastEntries[uuid[3]]!=undefined)  oldMonths=forecastEntries[uuid[3]]["months"];
 else oldMonths=response.months[0];
    forecastEntries[uuid[3]] = {
        startDate: response.startDate,
        finishDate: response.finishDate,
        recurring: response.recurring,
        amount:response.amount,
        vat:vat,
        disabled:response.disabled,
        recurringInterval: {label :response.recurringInterval["label"], value: response.recurringInterval["@id"]},
        months:response.months[0],
         oldMonths:oldMonths        
        };

  
      if(debug)console.log(uuid[3],'uuid');
      if(debug)console.log(forecastEntries[uuid[3]],'set forecastEntries');
      if(debug)debugThisObject(forecastEntries,'debug forecastEntries');
      //forecastEntries[uuid[3]]["oldMonths"]=forecastEntries[uuid[3]]["months"];
}


function getEntryPeriods(months) {
    let entryPeriods = [];
    let amountPeriod = 0;
    let month = null;
    //let months = [];
    let periodId = "";
    let monthPeriod = "";

  for (month in months) {
    if (
      month == "category" ||
      month == "poste" ||
      month == "type_id" ||
      month == "type" ||
      month == "disabled" ||
      month == "category_id"
    ) {
      break;
    } else if (month == "id") {
      break;
    } else if (params.rowPinned !== undefined) break;
    //if(debug)console.log(params);
    // example :  month = 200301
    monthPeriod = month.substring(4);
   // months.push(month);
   // amountPeriod = dataMonths[month];
    periodId = null;

    if (newEntry == true) {
      startDate = month.substring(0, 4) + "-" + monthPeriod + "-01";
      amount = amountPeriod;
    }

    //if(debug)console.log(month);
    if (periods != undefined) periodId = periods[month];

    //if(debug)console.log(" periodid "+periodId);
    // if(debug)console.log("amount "+ amountPeriod);
    if (periodId != undefined && periodId != null && amountPeriod != "0") {
      entryPeriods.push({
        id: periodId,
        amount: amountPeriod,
        period: monthPeriod,
      });
    } else if (!isNaN(amountPeriod) && amountPeriod != "0") {
      entryPeriods.push({
        //id:null,
        amount: amountPeriod,
        period: monthPeriod,
        recurringInterval: "/api/intervals/9",
      });
    }
    //if(debug)console.log(month +";"+params.data[month]+":"+params.data.periods[month]);
  }
  return entryPeriods;
}
  //function updateSummaries(months,col,amount,vatRate){
  function updateSummaries(col,amount,vatRate,totalAmount){
    let months=monthsColums;
    var topPinnedRow= gridRef.current.api.getPinnedTopRow(0);
    var bottomPinnedRow= gridRef.current.api.getPinnedBottomRow(0);
    let vatCol='';
    let vatAmount=0;

    // let vatRow = gridRef.current.api.getRowNode(0);

    // const year = col.slice(0, 4);
    // let monthPeriod=col.slice(4);

    // if(monthPeriod<12) vatCol=eval(col)+1;
    // else {
    //     vatCol=eval(year)+1+'01';
    //    }


     
    // if(vatRate!=0 && vatRow.data[vatCol]!==undefined){
    //   vatAmount=amount-(amount/(1+(vatRate/100)));
    //   vatAmount=Math.round(vatAmount);
    //   vatRow.setDataValue(vatCol, vatRow.data[vatCol] +vatAmount);
    //  } 
    //if(debug)console.log(amount);

     if(vatScheme.value=='/api/vat_schemes/1'){
       let  vatResult=updateVat(col,amount,vatRate);
       vatCol=vatResult[1];
       vatAmount=vatResult[0];
     }

  //  months.forEach
    for (const [key, month] of Object.entries(months)) 
{ 
      let vatAmountMonthStart=0;
      let vatAmountMonthFinish=0;
      if(vatScheme.value=='/api/vat_schemes/1'){
         if(vatCol<month)vatAmountMonthStart=vatAmount;
         if(vatCol<=month) vatAmountMonthFinish=vatAmount;
      }

      // if(isNaN(amount)) amount = 0;
      // amount=Number(amount);

       if(isNaN(amount)) totalAmount = 0;
       amount=Number(totalAmount);

      let newTopPinnedValue= topPinnedRow.data[month]+ amount -vatAmountMonthStart;
      let newBottomPinnedValue= bottomPinnedRow.data[month]+ amount -vatAmountMonthFinish;
     if(debug)console.log(topPinnedRow.data[month]+ ' top_'+col);
      if(debug)console.log(key, month,col,amount, topPinnedRow.data[month], newTopPinnedValue, newBottomPinnedValue , vatAmountMonthStart,vatAmountMonthFinish );

     if(col>=month) topPinnedRow.setDataValue(month, newTopPinnedValue);
     if(col<=month) bottomPinnedRow.setDataValue(month, newBottomPinnedValue);
    }
  }

   function getNextColumnMonth(col){
      const year = col.slice(0, 4);
      let monthPeriod=col.slice(4);
      let vatCol='';
       //if(debug)console.log(year +' ' +monthPeriod);
       if(monthPeriod<12) vatCol=eval(col)+1;
       else {
           vatCol=eval(year)+1+'01';
          }

      return vatCol;
   }


  function updateVat(col,amount,vatRate){

   let vatRow =  gridRef.current.api.getPinnedTopRow(1);

   let vatCol=getNextColumnMonth(col);
   let vatAmount=0;
   
    if(vatRate!=0 && vatRow.data[vatCol]!==undefined){
       //vatAmount=Math.round(amount*vatRate/100);
      vatAmount=amount-(amount/(1+(vatRate/100)));
      vatAmount=Math.round(vatAmount);
      let newVatValue=vatRow.data[vatCol] +vatAmount;
      if(debug)console.log(vatCol, amount +':'+vatRate, vatAmount, vatRow.data[vatCol], newVatValue);

      vatRow.setDataValue(vatCol.toString(),newVatValue);
     } 

     return [vatAmount,vatCol];
  }

   function updateVatColMonth(col,diffVatAmount){

    let vatRow =  gridRef.current.api.getPinnedTopRow(1);
    //if(debug)console.log(col);

    let newVatamount=vatRow.data[col] +diffVatAmount;
   if(debug)console.log(vatRow.data[col] + ' '+ diffVatAmount + ' '+newVatamount);
    if(vatRow.data[col]!==undefined){
    vatRow.setDataValue(col.toString(),newVatamount);
      }
     return [diffVatAmount,col];
  }

  function getVatMonth(col,diffVatAmount){

     const year = col.slice(0, 4);
    let monthPeriod=col.slice(4);
    let vatCol='';
    //if(debug)console.log(year +' ' +monthPeriod);
    if(monthPeriod<12) vatCol=eval(col)+1;
    else {
        vatCol=eval(year)+1+'01';
       }
  if(debug)console.log(vatCol);

  // 
    // let vatRow =  gridRef.current.api.getPinnedTopRow(1);



    //if(debug)console.log(vatRow.data[vatCol]);
    //   let newVatamount=vatRow.data[vatCol];
    // if(vatRow.data[vatCol]!==undefined){
    //    newVatamount=vatRow.data[vatCol] +diffVatAmount;
    //   vatRow.setDataValue(vatCol.toString(),newVatamount);
    //  } 

     return [diffVatAmount,vatCol];
  }

  function agGridAddPeriodsSingleCellOldVersion(response, rowNode,typeId,params,fromModal) {
      for (const [month, value] of Object.entries(dataMonth)) {
      {
        for (const data of Object.entries(value)) {
          //  let periodId=value["@id"].split("/");
          //  var c = value.period;
          //  p.push([year+c,periodId[3]]);
          //   if(c==12)year++;

          let amount=data[1];
          if(debug)console.log(monthsColums);

            if(!monthsColums.includes(month))       continue;
   
                   if(debug)console.log(month, data[0], data[1]);
          if (data[0] == "id") p[month] = amount;
          // p.push(month : data[1]);
          else  if (data[0] == "amount") {
           if(debug)console.log(month, data[0], data[1]);
            rowNode.setDataValue(month, data[1]);
            let newAmount=0;
           // if(singleCell){
                  if(month==params.colDef.field){
                    
                    newAmount=amount-oldValue;
                      
                    //  if(params.data.type_id=="1")newAmount=-newAmount;
                  if(typeId==1 || typeId=="Décaissement"){
                      newAmount=-newAmount;
                     if(debug)console.log(newAmount);
                  }
                  //if(debug)console.log(newAmount);
                  // if(newAmount!=0)
                  let vatRate=0;
                  updateSummaries(month,newAmount,vatRate);
                }
           // else {newAmount=0}
            //}
            // else {
            //     newAmount=amount;
            //     if(typeId==1 || typeId=="Décaissement"){
            //     newAmount=-amount;
            //    if(debug)console.log(newAmount);
            //   }
            //  if(debug)console.log(newAmount);
            //  // if(newAmount!=0 && fromModal)updateSummaries(monthsColums,month,newAmount);
            //   if(fromModal)updateSummaries(monthsColums,month,newAmount);

            // }

          }
        }
 } 
        // let oldValue = 0;
        // if (params.oldValue !== undefined) oldValue = params.oldValue;
        // let newAmount = params.newValue - oldValue;
        // if (params.data.type_id == "1") newAmount = -newAmount;

        //           if (col < month)
        //             rowNode.setDataValue(
        //               month,
        //               topPinnedRow.data[month] + amount
        //             );
        //           if (col <= month)
        //             bottomPinnedRow.setDataValue(
        //               month,
        //               bottomPinnedRow.data[month] + amount
        //             );
        // updateSummaries(months, params.colDef.field, newAmount);
      }
  }

  function agGridAddPeriodsSingleCell(month,amount,rowNode,typeId,params) {
     let oldValue=0;
    if(debug)console.log(params, 'params');
      if(params.oldValue!==undefined )oldValue=params.oldValue;
 //if(debug)console.log(month, data[0], data[1]);
    //rowNode.setDataValue(month, amount);
    let  newAmount=amount-oldValue;
        if(debug)console.log(newAmount +':' +oldValue + ':'+month);     
     if(typeId==1 || typeId=="Décaissement"){
              newAmount=-newAmount;
             if(debug)console.log(newAmount);
          }

      let vatRate=forecastEntries[params.data.id].vat.label;
      //updateSummaries(monthsColums,month,newAmount,vatRate);
      updateSummaries(month,newAmount,vatRate);
     //   rowNode.setDataValue("periods", p);
  }

  function agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate,newEntry,entry,disabled,fromScenario) {
    
    let periods = [];

    let obj = "";
    let p = [];
    //let year="2023";
    //let dataMonth = null;
   if(debug)console.log(response,'response');
   if(debug)console.log(rowNode,'rowNode');
   //if(debug)console.log(params);
    modalIsOpen = true;
   // return '';
    //rowNode = gridRef.current.api.getDisplayedRowAtIndex(rowNode.rowIndex);
    if(debug)console.log(typeId,'typeId');
     let data=params.data;
     let type_id='';
  if(fromModal)type_id=rowNode.data.type_id;
  else type_id=data.type_id
    let oldValue=0;
    let singleCell=false;
    if(rowNode.oldValue!==undefined || !fromModal){
      if(rowNode.oldValue!==undefined)oldValue=rowNode.oldValue;
      //if(debug)console.log(params.oldValue);
      singleCell=true;
    }

    if (response.data !== undefined) {
      data = response["months"][0];
    }
    else {
      data={};
     //if(debug)console.log(response["test"][0]);
         Object.entries(response["months"][0]).forEach(([month, value]) => {
            if(debug)console.log(month +' ' +value.amount+' '+value.vatAmount,'month');
//data.push({month:value["amount"]})
         data[month]=value["amount"];
              console.log(data);
});
     // data = response["test"][0];
     console.log(data);
if(debug)console.log(data,'data');
    }
    let mustUpdateSummaries=false;
    //monthsColums;
    console.log(singleCell +' new entry :' + newEntry);
            if(!singleCell){
                console.log(singleCell);
               if(!disabled) mustUpdateSummaries=true;
            //  if(rowDragCategory=="A catégoriser" || selectedCategory.label =="A catégoriser" || newEntry)mustUpdateSummaries=true;
                 //  agGridPeriodsUpdateData(rowNode,params.data,response["disabled"],vatRate,dataMonth,updateSummaries);
       
    
               agGridPeriodsUpdateData(rowNode,data,disabled,vatRate,mustUpdateSummaries,type_id,newEntry,entry);
            // monthsColums.forEach( month => {
              
            //     let newAmount=0;
            //     let oldAmount= 0;
            //     if(params.data[month]!==undefined)oldAmount=params.data[month];
                
            //     if(dataMonth[month]!==undefined) newAmount= dataMonth[month]['amount'];
          
            //     if(params.data["disabled"]==true || response["disabled"]==true)newAmount=0;
            //    if(debug)console.log("jk "+newAmount +'' + oldAmount);
            //     if(newAmount!=oldAmount){

            //         if(response["disabled"]!==true)rowNode.setDataValue(month,  newAmount);
            //         let amountDiff=newAmount-oldAmount;
            //         if(typeId==1 || typeId=="Décaissement")amountDiff=-amountDiff;

            //         updateSummaries(monthsColums,month,amountDiff,vatRate);
            //       }

            //   });
            }
    //if(debug)console.log("202305 : " + dataMonth["202305"]["amount"] + dataMonth["202305"]["id"]);
  else {
    //# only for single cell

      // obj=Object.fromEntries(p);
      //if(debug)console.log(p);
     // rowNode.setDataValue("periods", p);
    }

    // let uuid = response["@id"].split("/");
    // rowNode.setDataValue("id", uuid[3]);
    // rowNode.setDataValue("poste", response["name"]);
    //if(debug)console.log(rowNode);
    //if(debug)console.log(rowNode.data.periods['202302']);
    // setTimeout(() => {
    //   modalIsOpen = false;
    // }, 1000);
//modalIsOpen=false;

    setTimeout(() => {
      modalIsOpen = false;
    }, 1000);
  }

//function agGridPeriodsUpdateData(rowNode,data,disabled,vatRate,dataMonth,typeId,updateSummaries){

function agGridPeriodsUpdateData(rowNode,data,disabled,vatRate,mustUpdateSummaries,type_id,newEntry,entry){
   let totalAmount=0;
   let vatAmount=[];
   let vatResult=[];
    monthsColums.forEach( (month) => {
      
      let newAmount='0';
      let oldAmount= '0';
      let amountDiff='0';

       //  oldAmount=data["oldMonths"][month]["amount"];
       // oldAmount=forecastEntries[data.id]["oldMonths"][month]["amount"];
        if(data[month]!==undefined && !newEntry)oldAmount=data[month];
      if(debug)console.log(data[month] +' ' +month,'data month');

      if(debug)console.log(forecastEntries);

            if(debug)console.log(data,"data");
         newAmount=Number(data[month]);
   // if(debug)console.log(forecastEntries[data.id] +' data.id :'+data.id);
     //if(debug)console.log(forecastEntries[data.id]["months"]);
     // // if(oldAmount!=forecastEntries[params.data.id]["months"][month]
      if(entry!==undefined)  {
         if(entry["oldMonths"]!==undefined)  {
            if(entry["oldMonths"][month]!==undefined)  oldAmount=entry["oldMonths"][month]["amount"];
         }
      }
   


         if(newAmount===undefined  || isNaN(newAmount))newAmount=0;
        // if(dataMonth!="") if(dataMonth[month]!==undefined) newAmount= dataMonth[month]['amount'];

        if(disabled==true)newAmount='0';
        if (newEntry)oldAmount='0';
        newAmount=Number(newAmount);
        oldAmount=Number(oldAmount);
       if(debug)console.log(month+ " disabled :"+ newEntry +" : "+ disabled +" : "+newAmount +' : ' + oldAmount);
       
        if(newAmount!=oldAmount || mustUpdateSummaries==true || newEntry){
             

            if(disabled!==true)rowNode.setDataValue(month,  newAmount);
            amountDiff=newAmount-oldAmount;
            if(debug)console.log(rowDragCategory +" :"+ vatRate);
            if(mustUpdateSummaries==true )amountDiff=newAmount;
            if(type_id==1)amountDiff=-amountDiff;
            if(debug)console.log(month +" : "+amountDiff +' : ' + vatRate , totalAmount,newAmount);
           // updateSummaries(monthsColums,month,amountDiff,vatRate);
            
          }
            vatResult=vatUpdateCalculate(month,amountDiff,vatRate);
            console.log(vatResult);
            vatAmount[vatResult[1]]=Number(vatResult[0]);
             if(vatAmount[month]===undefined)vatAmount[month]=0;
             totalAmount=  totalAmount+newAmount-vatAmount[month];
             console.log(month, newAmount, vatAmount[month]);
            updateSummaries2(month,amountDiff,totalAmount);

           
           // totalAmount=totalAmount   +  newAmount+vatAmount[month];
      });
}


   function vatUpdateCalculate(col,amount,vatRate){
      let vatCol='';
      let vatAmount='0';
      let vatResult='';
      if(vatScheme.value=='/api/vat_schemes/1'){
          vatResult=updateVat(col,amount,vatRate);
        
           vatCol=vatResult[1];
           vatAmount=Number(vatResult[0]);

        }

      // let vatAmountMonthStart='0';
      // let vatAmountMonthFinish='0';
      // if(vatScheme.value=='/api/vat_schemes/1'){
      //    if(vatCol<month)vatAmountMonthStart=vatAmount;
      //    if(vatCol<=month) vatAmountMonthFinish=vatAmount;
      // }

      // vatAmountMonthStart=(vatAmountMonthStart);
      // vatAmountMonthFinish=(vatAmountMonthFinish);

      return vatResult;
   }

  //function updateSummaries(months,col,amount,vatRate){
  function updateSummaries2(col,amount,totalAmount){
   let months=monthsColums;
 //  var topPinnedRow= gridRef.current.api.getPinnedTopRow(0);
   var bottomPinnedRow= gridRef.current.api.getPinnedBottomRow(0);


     let month=col;
    // let vatRow = gridRef.current.api.getRowNode(0);

    // const year = col.slice(0, 4);
    // let monthPeriod=col.slice(4);

    // if(monthPeriod<12) vatCol=eval(col)+1;
    // else {
    //     vatCol=eval(year)+1+'01';
    //    }


     
    // if(vatRate!=0 && vatRow.data[vatCol]!==undefined){
    //   vatAmount=amount-(amount/(1+(vatRate/100)));
    //   vatAmount=Math.round(vatAmount);
    //   vatRow.setDataValue(vatCol, vatRow.data[vatCol] +vatAmount);
    //  } 
    //if(debug)console.log(amount);



      //  if(isNaN(amount)) amount = 0;
      // amount=Number(amount);

       if(isNaN(amount)) totalAmount = '0';
        amount=Number(totalAmount);
     
      //let newTopPinnedValue= Number(topPinnedRow.data[month])+ amount ;
      let newBottomPinnedValue= Number(bottomPinnedRow.data[month])+ amount;
      // if(debug)console.log(topPinnedRow.data[month]+ ' top_'+col);
      // if(debug)console.log( month,col,amount, topPinnedRow.data[month], newTopPinnedValue, newBottomPinnedValue , vatAmountMonthStart,vatAmountMonthFinish );

     // if(col>=month) topPinnedRow.setDataValue(month, newTopPinnedValue);
     // if(col<=month) bottomPinnedRow.setDataValue(month, newBottomPinnedValue);
     // if(debug)console.log( month, topPinnedRow.data[month], newTopPinnedValue );

      updateTopPinnedRow(getNextColumnMonth(month).toString(), newBottomPinnedValue);
      updateBottomPinnedRow(month, newBottomPinnedValue);


   // }
  }    

  function updateTopPinnedRow(month,amount){
      console.log(month);
      var topPinnedRow= gridRef.current.api.getPinnedTopRow(0);
      if(topPinnedRow.data[month]==undefined)return false;
      console.log(topPinnedRow.data);


      console.log(month,amount, topPinnedRow.data[month],isNaN(topPinnedRow.data[month]) , typeof amount, typeof topPinnedRow.data[month]);
     
      if(amount== topPinnedRow.data[month] ) return false;
   //   if(isNaN(amount)) amount=amount.toString();

    //  if(month=='202510')amount=amount.toString();
     // gridRef.current.api.getPinnedTopRow(0).setDataValue('202510', '116414');
       //     gridRef.current.api.getPinnedTopRow(0).setDataValue('202501', '1');
     // if(month=='202501'){
     //  let a=Number(topPinnedRow.data[month])+10000;
     // // a=20000;
     //  console.log(month,a);
     // // alert(amount);
     //  topPinnedRow.setDataValue(month,a.toString());
     // } 
    let a = topPinnedRow.setDataValue(month, amount);
    if(!a){
      amount=(amount).toString();
      a = topPinnedRow.setDataValue(month, amount);
    }
    console.log(a);
 }

   function updateBottomPinnedRow(month,amount){
      var bottomPinnedRow= gridRef.current.api.getPinnedBottomRow(0);
      console.log(month,amount);
     let a =bottomPinnedRow.setDataValue(month, amount);
      if(!a){
      amount=(amount).toString();
      a = bottomPinnedRow.setDataValue(month, amount);
      }
 }

function entryModalOnClose(){
   console.log(modalIsOpen);
   entryModal.onClose();
   modalIsOpen=false;
   console.log(modalIsOpen);
}
function customInnerRenderer(params){
    //this verification is necessary, otherwise the grid will brake down
    //when last level without grouping will be reached (if exists)
    if (params.node.group) {
        var label = params.value ? params.value : '-';
        //if(debug)console.log(params.node.childrenAfterFilter);
 //if(debug)console.log(params.node.rowIndex);
  //  var rowNode = gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex);

  //         rowNode.setDataValue('202301', '1000');

  //        params.node.childrenAfterFilter.forEach( poste => {
  //         //if(debug)console.log(poste.data["202301"]);

  //        });
  //        
        return    label  ;
        //return label + ' <b>(' +( params.node.childrenAfterFilter.length ) + ')' +'</b>';
    }
    else  {

return  " <span style={{display:'inline-block'}}>  <FaUniversity style={{display:'inline-block',marginRight:'10px'}} />{label}</span>";
       //return '<span><i class="material-icons">edit</i>' + label + '</span>';
     // return  label = params.value ? params.value : '-';

    }
}

  const getContextMenuItems = useCallback((params) => {
       var result=[];
  if(debug)console.log(params);
   if(params.node===undefined) {
          result.push({  action: () => { },});
      return '';
   }
   if(params.node.parent===undefined) {
          result.push({  action: () => { },});
      return '';
   }
if(debug)console.log(params.node.parent.key);
if(debug)console.log(params.node.key);

//let categoryType= getFrontCategories2(params.node.parent.key);
let isCategorizeType= isCategorizeType2(params.node.key);
//if(debug)console.log(categoryType);
if(debug)console.log(isCategorizeType);

    // start et end 
    if(params.node.rowPinned!=undefined) {
      console.log("e");
      result.push({  name:'',action: () => { },});
      return  ''; 
   }
    // every entry 
    if(params.node.level==2)  {
       result.push({  name:'Modifier',action: () => { },});
      return  result; 
   }

    if(params.node.level==0 && !isCategorizeType)  {

     result.push(
      {
        // custom item
        name: 'Ajouter une  nouvelle catégorie ',
        action: () => {
          if(debug)console.log("categorie");


         setCategoryType('1');
          let type=params.node.key;

          if(type=="Encaissements"){
          // setToCategrorize(entryToCategorizeRevenue),
            setCategoryType('2');
          }
    
       //if(debug)console.log(categoryType);
   
      setCategoryName('');
      setCheckedItems([]);
     // test2('api/entries/sdfsfsf');
      categoryNewModal.onOpen();
[]
      },
        cssClasses: ['redFont', 'bold'],
      });
    }     
   // level 1 and 0
   else if(params.node.level==1) {
      // if( params.value== entryToCategorize){
      //     result.push(
      // {
      //   // custom item
      //   name: 'non disponible',
      //   action: () => {
  
      //   },
      //   cssClasses: ['redFont', 'bold'],
      // });
      // }
     // else  result.push(
    //  {
    //  
         // let label="";
         // if(categoryType=="1") label=" décaissement ";
         // else  if(categoryType=="2") label=" encaissement ";
result.push({
        // custom item
         //name: 'Ajouter un '+label+' dans <b> ' + params.value+"</b>",
         name: 'Ajouter un nouveau poste dans <b> ' + params.value+"</b>",
        action: () => {
         if(debug)console.log(params);
          var category_id=null;
          var type_id=params.node.parent.key;
          var categoryType='1';
          if(type_id=='Encaissements')categoryType='2';
          if(params.node.allLeafChildren[0]!=undefined){
          category_id=params.node.allLeafChildren[0].data.category_id;
          type_id=params.node.allLeafChildren[0].data.type_id;;
          }
  if(debug)console.log(params.value);
  if(debug)console.log(category_id);
          if(params.node.groupData!=undefined)if(debug)console.log(params.node.groupData.poste);
          if(params.node.parent!=undefined)if(debug)console.log(params.node.parent.key);
          if( params.value!= entryToCategorize){
               openNewModal(categoryType,category_id,params.value);
          // let row= [{category:params.value,category_id:category_id,type:params.node.parent.key,type_id:type_id,poste:"nouveau",id:''}];
          // let a =params.api.applyTransaction({add:row});
          //if(debug)console.log(a);
          //       if(debug)console.log(a["add"][0]);
          //if(debug)console.log(a["add"][0]["id"]);
          // let b = params.api.getRowNode(a["add"][0]["id"]);
          //if(debug)console.log(b)
          // openModal(b);
          }
          else window.alert('Veuillez choisir une autre catégorie');
        },
        cssClasses: ['redFont', 'bold'],
      });
    }
    return result;
  }, []);

function isCategorizeType2(name){
   let isCategorizeType=false;
   if(name=='Décaissements à catégoriser')isCategorizeType=true;
   else if(name=='Encaissements à catégoriser')isCategorizeType=true;
   return isCategorizeType;
}

 function getFrontCategories2(name){
   let typeCategory=''
   if(name=='Encaissements')typeCategory="2";
   else if(name=='Décaissements')typeCategory="1";
   return typeCategory;
  }

  function test2(id){
    const itemsToUpdate = [];
     if(debug)console.log(gridOptions);
   //let rows= gridOptions.api.forEachNodeAfterFilterAndSort(test3);
   //if(debug)console.log(rows);
   // const res = gridApi.applyTransaction({ update: itemsToUpdate });
   // printResult(res);

  }

  function applyCategory(data){

     if(debug)console.log(data);
        // if(debug)console.log(data);
          let row= [];
      if(data.data!==undefined) {
        row.value="api/forecast_entries/"+data.data.id;
        row.id=data;
       if(debug)console.log(row);
    
       }
       let itemsToUpdate=[];
         let rows=   Object.entries(checkedItems).forEach(([key, value]) => {
          if(row.value !== undefined) {
            
            if (value==row.value) {
              
             if(debug)console.log(row.id.data+ 'bung' +value);
              const data = row.id.data;
              data.category=categoryName;
               data.category_id=categoryId;
              itemsToUpdate.push(data);
              const res = gridApi.applyTransaction({ update: itemsToUpdate });
            }
          }
//if(debug)console.log(value);
                //if(debug)console.log(value);
         // return value;
        });
     // let rows=[];
      // if(rowNode.data!==undefined) {
      //  if(debug)console.log(rowNode.data.id + ':::'+id);
      //   rows.push(rowNode.data.id);
      // }
     // return rows;
  }

const [categoryName, setCategoryName] = useState(null);
const categoryNameChange= (name)=>{
 if(debug)console.log("name" + name);
  setCategoryName(name);
}

const [categoryType, setCategoryType] = useState(null);
const [categoryId, setCategoryId] = useState(null);
const [company, setCompany] = useState(null);
//let company=null;
const [checkedItems,setCheckedItems] = useState([]);

function onEntriesCategorized(value,isChecked){
  //if(debug)console.log(gridOptions);
  // if(debug)console.log(gridApi);
  // gridApi.forEachNodeAfterFilterAndSort(test3);
    let previousItems=[...checkedItems];
   if(debug)console.log(value);
    if(isChecked==false){
      var index = previousItems.indexOf(value);
      if (index >= 0) {
        previousItems.splice( index, 1 );
        setCheckedItems( previousItems);
      }
    }
    if(isChecked==true){
    setCheckedItems([...checkedItems, value ])
    }
}

function removeItemToCategorize(value,categoryType){
  let items=[];
 if(debug)console.log(value+ ' '+categoryType);
  if(categoryType==1) items=[...expenseToCategorize];
  else items=[...revenueToCategorize];
  var index = items.indexOf(value);
if(debug)console.log(items);
if(debug)console.log(expenseToCategorize);
if(debug)console.log(revenueToCategorize);

  const row = items.findIndex(row => row.includes(value));

  if(debug)console.log(value);
  if(debug)console.log(row);

  const col = items[row].indexOf(value);
 if(debug)console.log(row+' '+col);
   if (row >= 0) {
      items.splice( row, 1 );
      if(categoryType==1) setExpenseToCategorize( items);
       else setRevenueToCategorize( items);
    }
  }
  
function switchItemCategory(value,categoryType){
  let items=[];
 if(debug)console.log(value+ ' '+categoryType);
  if(categoryType==1) items=[...expenseToCategorize];
  else items=[...revenueToCategorize];
  var index = items.indexOf(value);

  const row = items.findIndex(row => row.includes(value));
  const col = items[row].indexOf(value);
 if(debug)console.log(row+' '+col);
   if (row >= 0) {
      items.splice( row, 1 );
      if(categoryType==1) setExpenseToCategorize( items);
       else setRevenueToCategorize( items);
    }
  }

  // function putCategory(id,categoryName,categoryTypeId,entries){
  //     let result= cashFlowApi.putCategory(id,categoryName,categoryTypeId,entries);

  //       result.then( (categorie) =>{
  //      if(debug)console.log(categorie);
  //      if(debug)console.log(categorie["@id"]);
  //      // category_id=categorie["@id"];
  //      // setCategoryId(category_id);
  //       categoryModal.onClose();
  //       });
  // }

  function putCategory(categoryId,entryId){

      //let result= cashFlowApi.putCategory(id,categoryName,categoryTypeId,entries);
      let result= cashFlowApi.putEntryCategory(entryId,categoryId);

        result.then( (categorie) =>{
       if(debug)console.log(categorie);
       if(debug)console.log(categorie["@id"]);
       // category_id=categorie["@id"];
       // setCategoryId(category_id);
        categoryModal.onClose();
        });
  }

// let lineChartDataDefault=[
//   {
//     name: "Encaissements",
//     data: [350, 40, 300, 220, 500, 250, 400, 230, 500],
//   },
//   {
//     name: "Décaissements",
//     data:  [450, 50, 300, 220, 200, 250, 300, 430, 500],
//   },
// ]  ;


  const [balancesSeries, setBalancesSeries] = useState([]  );
  const [lineChartDataDefault, setLineChartDataDefault] = useState([]  );
  const [message, setMessage] = useState(  );



 const barChartOptionsCharts1 = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      backgroundColor: "red",
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["16-20", "21-25", "26-30", "31-35", "36-42", "42+"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#c8cfca",
        fontSize: "14px",
      },
    },
  },
  grid: {
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    colors: ["#151928"],
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "40px",
    },
  },
};
 
 let datas={
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };
let firstData=[
        {
          name: "blue",
          data: [32]
        },
        {
          name: "green",
          data: [41]
        },
        {
          name: "yellow",
          data: [12]
        },
        {
          name: "red",
          data: [65]
        }
];
  const [barChartDataCharts1, setBarChartDataCharts1] = useState(firstData);

//setBarChartDataCharts1(firstData);
  useEffect(() => {
   console.log(barChartDataCharts1);
  }, [barChartDataCharts1]);

  const updateChartData = () => {
   setMessage(message+'to');
    
   console.log("boom");

//     newBarSeries.push({ data, name: s.name });

    //const newData = chartData.datasets[0].data.map(() => Math.floor(Math.random() * 100));
   let  newData= [
        {
          name: "blue",
          data: [62]
        },
        {
          name: "green",
          data: [71]
        },
        {
          name: "yellow",
          data: [32]
        },
        {
          name: "red",
          data: [55]
        }
];
    setBarChartDataCharts1(newData);
    console.log(barChartDataCharts1);
  };


function getMonthNames(date = new Date()) {
  // Copy input date so don't modify it
  let d = new Date(+date);
  // Set to 1st of month
  d.setDate(1);
  let result = [];
  // Get 12 month names, starting with current month
  for (let i=0; i<12; i++) {
    result.push(d.toLocaleString('default',{month:'long'}));
    d.setMonth(d.getMonth() + 1);
  }
  return result;
}


 const balancesChartOptions = {

  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {

    categories: getMonthNames(),


    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
    noData: {
    text: 'Chargement...'
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#169bff"],
  },
  colors: ["#169bff"],
};


 const lineChartOptions= {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {

    categories: getMonthNames(),


    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "12px",
      },
    },
  },
    noData: {
    text: 'Chargement...'
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#4FD1C5", "#d14f5b"],
  },
  colors: ["#4FD1C5", "#d14f5b"],
};

    
        const chartOptions = {
//          theme: {
//   palette: 'palette10' // upto palette10
// },

  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#4FD1C5", "#d14f5b"],
  },
  colors: ["#4FD1C5", "#d14f5b"],
    categories: getMonthNames(),
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Traffic Sources'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
      //  labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        yaxis: [{
          title: {
            text: 'Website Blog',
          },
        
        // }, {
        //   opposite: true,
        //   title: {
        //     text: 'Social Media'
        //   }
        }]
        };
            const loadingOverlayComponentParams = useMemo(() => {
        return { loadingMessage: 'One moment please...' };
    }, []);


function activateScenario(scenarioId){
      console.log(scenarios[scenarioId]);
     //  updateTopPinnedRow('202510','10000');
      scenarios[scenarioId].forEach((value) => {
         console.log(value);

         console.log(forecastEntries[value]);
          let row = [
           // {
               scenarios[scenarioId][value]
               //{
              //  id: value,
              // category: forecastEntries[value].category,
              // category_id: forecastEntries[value].category_id,
              // type: forecastEntries[value].type,
              // type_id: forecastEntries[value].typeId,
              // poste: forecastEntries[value].name,
              // vat:forecastEntries[value].vat["rate"],
           // },
          ];
     // //if(debug)console.log(row);
    
      let a = gridRef.current.api.applyTransaction({ add: row});
      var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
      let fromScenario=true;
      agGridAddPeriods(scenarios[scenarioId][value], rowNode,forecastEntries[value].type_id,params,1,forecastEntries[value].vat["label"],'1',forecastEntries[value],false,fromScenario);
              });
      // let type='Décaissements';
      // if (typeId == "2")  type = "Encaissements";
      //  let uuid = response["@id"].split("/");

 }

   function desactivateScenario(scenarioId){
      console.log(scenarios[scenarioId]);
 

       scenarios[scenarioId].forEach((value) => {
                 console.log(value);
             //      console.log(forecastEntries[value]);
             // let row = [
             //      scenarios[scenarioId][value]
             // ];
            // //if(debug)console.log(row);
          //  let a = gridRef.current.api.applyTransaction({ add: row});
          //  var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
                 var rowNode = gridRef.current.api.getRowNode(value); 
         console.log(rowNode);
         let fromScenario=true;
            agGridAddPeriods(scenarios[scenarioId][value], rowNode,forecastEntries[value].type_id,params,1,forecastEntries[value].vat["label"],1,forecastEntries[value],true,newTotalRevenue,fromScenario);
            const res = gridRef.current.api.applyTransaction({ remove: [rowNode.data] });

         });
    }

   function sendCategory(categoryId){
  
        var category_id=null;
        let entries = [];
        let newEntries = [];
        let categoryTypeId = "api/forecast_types/"+categoryType;
        let type='';
       if(debug)console.log(categoryName +' '+categoryId);
        if (categoryName=="") return;
          if (categoryType == "2") {
            type = "Encaissements";
            // entryByRevenueCategories[categoryName];
            if (entryByRevenueCategories[categoryName] !== undefined) {
              entryByRevenueCategories[categoryName].forEach((value) => {
                entries.push(value);
              });
            }else {
              entryByRevenueCategories[categoryName]=[];
            }
          } else {
            type = "Décaissements";
           if(debug)console.log(entryByExpenseCategories[categoryName]);
            if (entryByExpenseCategories[categoryName] !== undefined) {
              entryByExpenseCategories[categoryName].forEach((value) => {
                //if(debug)console.log(value);
                entries.push(value);
              });
            } else {
              entryByExpenseCategories[categoryName] = [];
            }
          }

        if(checkedItems.length>0) {
          //if(debug)console.log(checkedItems);
        Object.entries(checkedItems).forEach(([key, value]) => {
           if(debug)console.log(key + " " + value + " " + categoryType +' : ' +value.split("/")[2] );
            entries.push(value);
               // newEntries.push(value);
           
            if (categoryType == "2") entryByRevenueCategories[categoryName].push(value);
            else entryByExpenseCategories[categoryName].push(value);
            removeItemToCategorize(value,categoryType);
             let movingNode = gridApi.getRowNode(value.split("/")[2]); 
        }) 
       //if(debug)console.log(newEntries);


        }
        let  id = categoryId;
   
        let result= cashFlowApi.putCategory(id,categoryName,categoryTypeId,company,entries);
              result.then( (categorie) =>{
             if(debug)console.log(categorie);
             if(debug)console.log(categorie["@id"]);
              category_id=categorie["@id"];
             // setCategoryId(category_id);
              categoryModal.onClose();
              });

         // let row= [{category:categoryName,category_id:category_id,type:type,type_id:categoryType,poste:'',id:''}];
         // let  overNode=gridApi.applyTransaction({add:row});
         // if(debug)console.log(overNode);

         let movingNode =  gridApi.getRowNode(checkedItems[0].split("/")[2]); 

         let newEntry=true;
         if(movingNode.data.category=='A catégoriser') newEntry=true;

         let data=movingNode.data;
         data.category=categoryName;
         data.type=type;
         let itemsToUpdate= [];
         itemsToUpdate.push(data);
         if(debug)console.log(data);
         const res = gridApi.applyTransaction({ update: itemsToUpdate });
         let entry=undefined;
         agGridPeriodsUpdateData(movingNode,movingNode.data,movingNode.data.disabled,movingNode.data.vat,true,movingNode.data.type_id,newEntry,entry);

        // if(checkedItems.length<1) {
        //   let row= [{category:categoryName,category_id:category_id,type:type,type_id:categoryType,poste:'',id:''}];
        //   gridApi.applyTransaction({add:row});
        // }
        // else{
      //  gridApi.forEachNodeAfterFilterAndSort(applyCategory);
        // }
        // 

  }

 if (!rowData) {
     return null;
  }
  else  {
    return (
     
      <div>


          {/* <ModalW></ModalW> */}
          {/* {checkedItems} */}
          {/* {expenseToCategorize } */}
          <CategoryModal
            categoryType={categoryType}
            categoryNameChange={categoryNameChange}
            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            selectedCategory={""}
            onCategorySend={sendCategory}
            onEntriesCategorized={onEntriesCategorized}
            expenseToCategorize={expenseToCategorize}
            revenueToCategorize={revenueToCategorize}
            isOpen={categoryModal.isOpen}
            addCategory={addCategory}
            onClose={categoryModal.onClose}
            company={company}
            reportId={reportId}
            addNewCategory={addNewCategory}
          />

          {/* {expenseToCategorize } */}
          <CategoryEditModal
            categoryType={categoryType}
            categoryNameChange={categoryNameChange}
            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            categoryName={categoryName}
            selectedCategory={""}
            onCategorySend={sendCategory}
            onEntriesCategorized={onEntriesCategorized}
            expenseToCategorize={expenseToCategorize}
            revenueToCategorize={revenueToCategorize}
            isOpen={categoryEditModal.isOpen}
            addCategory={addCategory}
            onClose={categoryEditModal.onClose}
            company={company}
          />

          <CategoryNewModal
            categoryName={categoryName}
            categoryType={categoryType}
            categoryId={categoryId}
            onPostCategory={onPostCategory}
            company={company}
            isOpen={categoryNewModal.isOpen}
            addCategory={addCategory}
            onClose={categoryNewModal.onClose}

          />

          <DragnDropVatModal
            params={params}  
            entryId={id}   
            isOpen={dragnDropVatModal.isOpen}
            selected={vatScheme}
            onClose={dragnDropVatModal.onClose}
            onVatSelected={onVatSelected}
          />

          <VatModal
            params={params}  
            reportId={reportUuid}   
            isOpen={vatModal.isOpen}
            selected={vatScheme}
            onClose={vatModal.onClose}
            onVatSend={onVatSend}
            />

          <EntryModal
            params={params}
            categoryType={categoryType}
            categoryName={categoryName}
            categoryId={categoryId}
            selectedCategory={selectedCategory}
            openVatModal={openVatModal}
            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            bankAccount={bankAccount}
            onChange={categoryNameChange}
            reportId={reportId}
            putEntry={putEntry}
            addCategory={addCategory}
            monthsColums={monthsColums}
            fixedAmount={fixedAmount}
           // startDate={startDate}
            startDateFormated={startDateFormated}
           // finishDate={finishDate}
            finishDateFormated={finishDateFormated}
            vatSelected={vatSelected}
            recurring={recurring}
            disabled={disabled}
            recurringInterval={recurringInterval}
            isOpen={entryModal.isOpen}
            onClose={entryModalOnClose}
            addNewCategory={addNewCategory}
            company={company}
          />

      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(4, auto)",
          lg: "repeat(4, auto)",
        }}
        templateRows={{ md: "repeat(4, auto)", lg: "repeat(4, auto)" }}
        gap="10px"
      >

          <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Encaissements
                  </Text>
                  <Link onClick={newRevenue} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Créer
                  </Text>
                  </Link>
                  <Link onClick={categorizeRevenue} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                   ({revenueToCategorize.length}) à  Catégoriser
                  </Text>
                     </Link>
                  
                     {/* categoryEdit*/}
                     <Link onClick={()=>goCategories('revenue')} >
                    <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Gérer les catégories
                  </Text>
               </Link>
                    <Link onClick={()=>activateScenario("4e8d5142-91e6-11ef-98b6-00155d01903e")} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Scénario 1 
                  </Text>
                  </Link>

                        <Link onClick={()=>desactivateScenario("4e8d5142-91e6-11ef-98b6-00155d01903e")} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Retirer le scénario
                  </Text>
                  </Link>
                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                as={FaSignInAlt}
                h={"20px"}
                w={"20px"}
                      color={iconTeal}
              />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>

                    <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Décaissements
                  </Text>
                  <Link onClick={newExpense}>
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Créer
                  </Text>
                  </Link>
                  
                   <Link onClick={categorizeExpense} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    ({expenseToCategorize.length}) à Catégoriser 
                  </Text>
                  </Link>

                  <Link onClick={()=>goCategories('expense')} >
                        <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Gérer les catégories
                  </Text>
                  </Link>
                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                as={FaSignOutAlt}
                h={"20px"}
                w={"20px"}
                      color={iconTeal}
              />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>

         <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Solde actuel
                  </Text>

                  <Text fontSize="lg" color="#fff" fontWeight="bold">
                    {bankBalance} € 
                  </Text>
                  <br/>
                  <Text fontSize="xs" color="#fff" fontWeight="normal" mb="2px">
                   mise à jour le {bankBalanceDate}
                  </Text>
                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                    as={FaUniversity}
                    h={"24px"}
                    w={"24px"}
                    color={iconTeal}
                  />
                </IconBox>
              </Flex>
            </CardBody>
            </Card>


         <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Paramètres
                  </Text>
                  <Link onClick={openVatModal}>
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Régime et taux de TVA
                  </Text>
                  </Link>
      
                  <Link onClick={onBtExport}>
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Exporter sous Excel
                  </Text>
                  </Link>

                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                    as={FaCog}
                    h={"24px"}
                    w={"24px"}
                    color={iconTeal}
                  />
                </IconBox>
              </Flex>
            </CardBody>
            </Card>


</Grid>

      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(2, auto)",
          lg: "repeat(2, auto)",
        }}
        templateRows={{ md: "repeat(2, auto)", lg: "repeat(2, auto)" }}
        gap="10px"
      >
 {/*
     <Card p="28px 10px 0px 0px" mb={{ sm: "26px", lg: "0px" }}>
                      <Box w="100%" h={{ sm: "300px" }} ps="8px">
            <Chart
              series={lineChartDataDefault}
              options={lineChartOptions}
             type="area"
               width="100%"
               height="100%"
            />
          </Box>
          </Card>

      <Card p="28px 10px 0px 0px" mb={{ sm: "26px", lg: "0px" }}>
        <Box w="100%" h={{ sm: "300px" }} ps="8px">
        
            <Chart
              series={balancesSeries}
              options={balancesChartOptions}
              type="area"
              width="100%"
              height="100%"
            />
         
          </Box>
          </Card>
*/}
            {/* <CardHeader mb="20px" ps="22px">
           <Flex direction="column" alignSelf="flex-start">
              <Text fontSize="lg" fontWeight="bold" mb="6px">
                Mouvements
              </Text>
              <Text fontSize="sm" fontWeight="medium" color="gray.400">
                <Text as="span" color="green.400" fontWeight="bold">
                  (+5%) more
                </Text>{" "}
                in 2021
              </Text>
            </Flex>
          </CardHeader>

             <div>
               <button onClick={updateChartData}>Update Data</button>
             </div>
         */} 

            <Stack
            direction="row"
            spacing="20px"
            w="100%"
   
          >

            <Switch
              colorScheme="cyan"
              defaultChecked={showEnptyCategories}
              onChange={(e) => externalFilterChanged(e.target)}
            />
          <FormControl alignItems="center">
            <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
             Afficher les catégories vides
            </FormLabel>

          </FormControl>
</Stack>
</Grid>
      
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align="center"
            w="100%"
            mb="12px"
          >


          <div
              className="ag-theme-alpine"
              style={{ minHeight: "75vh", minWidth: "100%", top: 200 }}
            >

              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                onCellValueChanged={onCellValueChanged}
                gridOptions={gridOptions}
                columnDefs={columns}
                onGridReady={onGridReady}
                onRowDataUpdated={pinnedRow()}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                columnTypes={columnTypes}
                //onRowDragMove={onRowDragMove}
                onRowDragEnd={onRowDragEnd}
                onRowDragEnter={onRowDragEnter}
                    rowDragEntireRow={true}
                    loadingOverlayComponent={CustomLoadingOverlay}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                // pinnedBottomRowData= []
                //   pinnedBottomRowData={ pinnedRow(1, 'Bottom')}
              >
                {/* {columns.map(column => (
              <AgGridColumn {...column} key={column.field} />
            ))} */}
              </AgGridReact>

              {/* <AgGridReact
               rowData={rowData2}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions2}
                           >
          	{columns2.map(column => (
            <AgGridColumn {...column} key={column.field} />
          ))}                        
          </AgGridReact> */}
              </div>

          </Flex>
  
   
      </div>
    );
}

}

export default Forecast;
